<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <article class="card-body" v-if="language.id === 1"> <!-- Eng -->
            <h4 class="card-title">
                {{ trans('layout.about') }}
            </h4>

            <b-img src="/storage/images/about/logo.jpg" fluid class="mt-2"></b-img>

            <p class="mt-2">
                <strong>Sand of Siberia</strong> is a modification for the <a href="https://en.wikipedia.org/wiki/Original_War" target="_blank">Original War</a> game. The primary goal of the project is to expand the game, while maintaining a great atmosphere.
                If you are a veteran player of Original War and want to refresh the memories associated with it, then this mod is for you!
            </p>

            <h5 class="text-center mt-5">
                Content
            </h5>

            <p class="mt-2">
                The modification offers a number of elements. The main focus is was put on single-player gameplay, however, a network mode for playing with friends is also available.
            </p>

            <b-img src="/storage/images/about/us-camp-en.jpg" fluid class="mt-2"></b-img>

            <p class="mt-2">
                The story of an American soldier - John Macmillan, known from the basic version of the game. One of the main changes is the increased difficulty level and
                improved artificial intelligence, which makes enemies much more challenging than before.
                Some of the maps have been expanded, allowing to introduce new gameplay elements.
                This is an excellent choice for anyone who wants to refresh their skills after a years-long break from Original War.
            </p>

            <p class="mt-2">
                Features:

                <ul class="special">
                    <li>Campaign offers 19 missions with 4 difficulty levels.</li>
                    <li>Some of maps were expanded, which allowed to add a bundle of new gameplay features.</li>
                    <li>All missions have been rewritten from “scratch,” so that a mass of bugs from the basic version of the game have been eliminated.</li>
                    <li>The decisions made have an even greater impact on the course of events.</li>
                    <li>Each mission has a unique set of achievements.</li>
                </ul>
            </p>

            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7pK-xJkPt7A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <b-img src="/storage/images/about/ar-camp-en.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                A brand new campaign, based to some extent on the original notes for the Arab campaign, which did not make it into the basic version of the game.
                Take on the role of Heike Steyer, a mercenary hired by the Sheikhs to take part in the war for Siberite.
            </p>

            <p class="mt-2">
                Features:

                <ul class="special">
                    <li>The campaign has 10 brand new missions and 4 different difficulty levels.</li>
                    <li>All dialogues have full Polish dubbing, and the story itself has been enhanced with movie interludes between missions.</li>
                    <li>Arab toys such as a self-propelled mortar or a powerful flamethrower have been placed in the player's hands.</li>
                    <li>Each mission has a unique set of achievements.</li>
                </ul>
            </p>

            <b-img src="/storage/images/about/ru-camp-en.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                Refreshed story of the mechanic Yuri Ivanovich Gorky, enriched with many new elements.
            </p>

            <p class="mt-2">
                Features:

                <ul class="special">
                    <li>The campaign has 19 revamped missions and 4 different difficulty levels.</li>
                    <li>Totally redesigned Alliance's path on the Russian side.</li>
                    <li>Many new dialogues and gameplay features.</li>
                    <li>Improved enemy AI, makes war for Siberite quite a challenge even for veterans of the game.</li>
                    <li>Each mission has a unique set of achievements.</li>
                </ul>
            </p>

            <h6 class="mt-2">
                The campaign will be available from version 1.7.
            </h6>

            <b-img src="/storage/images/about/skirmish-en.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                Skirmish mode is a set of single-player scenarios, not plot-related.
            </p>

            <p class="mt-2">
                Features:

                <ul class="special">
                    <li>The skirmish mode includes 20 scenarios with three different levels of difficulty.</li>
                    <li>Offers a range of new solutions not seen in other modifications.</li>
                    <li>The dialogues have Polish dubbing.</li>
                    <li>Some maps have their own sets of achievements.</li>
                </ul>
            </p>

            <b-img src="/storage/images/about/multiplayer-en.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                The network mode has been expanded relative to the basic version of the game.
                It consists of two types: competition between players and a cooperative mode, where players can face the AI together.
            </p>

            <p class="mt-2">
                Features:

                <ul class="special">
                    <li>Offers 20 maps - 16 for competitive mode and 4 for cooperative mode.</li>
                    <li>The game observer has received several new features relative to the original, such as a preview of player resources and in-game match informations.</li>
                    <li>There are both classic maps, known from the basic version of the game, and brand new maps, created specifically for the modification.</li>
                    <li>Competitive mode maps offer 3 basic game rules: Classic Battle, Survival and King of the Hill.</li>
                </ul>
            </p>

            <h5 class="text-center mt-5">Other features</h5>

            <p class="mt-2">
                Classic GUI - modification offers a brand new interface based on the classic interface known from older versions of the game.
            </p>

            <b-img src="/storage/images/about/menu.png" fluid class="mt-3 mb-3"></b-img>

            <p class="mt-2">
                Achievements - list of quests and additional objectives with player can achieve. More than 100 is available at the moment.
            </p>

            <b-img src="/storage/images/about/achivs.png" fluid class="mt-3 mb-3"></b-img>

            <p class="mt-2">
                Biographies - each important character which player can meet while game has his own history.
            </p>

            <b-img src="/storage/images/about/bio.png" fluid class="mt-3 mb-3"></b-img>

            <p class="mt-2">
                .. and much, much more. We encourage any Original War player to try out our modification and share your feedback on our <a class="clickable" href="https://discord.gg/APWVVWQtBW" target="_blank">Discord</a>.
                The modification is available for free, and the only requirement to run it is to have the game installed.
            </p>

            <p class="mt-2">
                Don't wait and play now!
            </p>

            <router-link :to="{ name: 'download' }">
                <b-img src="/storage/images/about/download-en.jpg" fluid class="mt-3 mb-3"></b-img>
            </router-link>
        </article>

        <article class="card-body" v-else> <!-- Polski -->
            <h4 class="card-title">
                {{ trans('layout.about') }}
            </h4>

            <b-img src="/storage/images/about/logo.jpg" fluid class="mt-2"></b-img>

            <p class="mt-2">
                <strong>Sand of Siberia</strong> to modyfikacja do gry <a href="https://pl.wikipedia.org/wiki/Original_War" target="_blank">Original War</a>. Nadrzędnym celem projektu jest rozbudowa gry,
                z zachowaniem świetnego klimatu. Jeśli jesteś weteranem Original War i chcesz odswieżyć związane z nim wspomnienia, to ten mod jest dla Ciebie!
            </p>

            <h5 class="text-center mt-5">
                Zawartość
            </h5>

            <p class="mt-2">
                Modyfikacja oferuje szereg elementów. Główny nacisk stawiany jest na rozgrywkę dla pojedynczego gracza, jednakże dostępny jest również tryb sieciowy do gry ze znajomymi.
            </p>

            <b-img src="/storage/images/about/us-camp-pl.jpg" fluid class="mt-2"></b-img>

            <p class="mt-2">
                Historia amerykańskiego żołnierza - Johna Macmillana, znana z podstawowej wersji gry. Jedną z głównych zmian jest zwiększony poziom trudności i
                ulepszona sztuczna inteligencja, która sprawia, że wrogowie są znacznie większym wyzwaniem niż dotychczas.
                Część map została rozszerzona, co umożliwiło wprowadzenie nowych elementów rozgrywki.
                Jest to doskonały wybór dla każdego, kto chce odświeżyć swoje umiejętności po latach przerwy od Original War.
            </p>

            <p class="mt-2">
                Charakterystyka:

                <ul class="special">
                    <li>Kampania posiada 19 misji oraz 4 zróżnicowane poziomy trudności.</li>
                    <li>Część map została powiększona co umożliwiło dodanie wielu nowych elementów.</li>
                    <li>Wszystkie misje zostały napisane "od zera", dzięki czemu została wyeliminowana masa błędów z podstawowej wersji gry.</li>
                    <li>Podejmowane decyzje mają jeszcze większy impakt na przebieg wydarzeń.</li>
                    <li>Każda z misji posiada unikalny zestaw osiągnięć.</li>
                </ul>
            </p>

            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7pK-xJkPt7A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <b-img src="/storage/images/about/ar-camp-pl.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                Zupełnie nowa kampania, oparta w pewnym stopniu na oryginalnych notatkach dotyczących kampanii arabskiej, która nie trafiła do podstawowej wersji gry.
                Wciel się w Heike Steyer, najemnika zatrudnionego przez szejków do wzięcia udziału w wojnie o Syberyt.
            </p>

            <p class="mt-2">
                Charakterystyka:

                <ul class="special">
                    <li>Kampania posiada 10 zupełnie nowych misji oraz 4 zróżnicowane poziomy trudności.</li>
                    <li>Wszystkie dialogi posiadają pełny polski dubbing, a sama historia została wzbogacona o przerywniki filmowe pomiędzy misjami.</li>
                    <li>W ręce gracza zostały przekazane arabskie zabawki takie jak moździerz samobieżny czy potężny miotacz ognia.</li>
                    <li>Każda z misji posiada unikalny zestaw osiągnięć.</li>
                </ul>
            </p>

            <b-img src="/storage/images/about/ru-camp-pl.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                Odświeżona historia mechanika Jurij'a Iwanowicza Gorkiego, wzbogacona o wiele nowych elementów.
            </p>

            <p class="mt-2">
                Charakterystyka:

                <ul class="special">
                    <li>Kampania posiada 19 odświeżonych misji oraz 4 zróżnicowane poziomy trudności.</li>
                    <li>Totalnie przerobiona ścieżka Przymierza po stronie rosyjskiej.</li>
                    <li>Wiele nowych dialogów i elementów gameplay'owych.</li>
                    <li>Poprawione SI przeciwnika, sprawia że wojna o Syberyt jest sporym wyzwaniem nawet dla weteranów gry.</li>
                    <li>Każda z misji posiada unikalny zestaw osiągnięć.</li>
                </ul>
            </p>

            <h6 class="mt-2">
                Kampania będzie dostępna od wersji 1.7.
            </h6>

            <b-img src="/storage/images/about/skirmish-pl.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                Tryb potyczek to zestaw scenariuszy dla jednego gracza, niepowiązanych ze sobą fabularnie.
            </p>

            <p class="mt-2">
                Charakterystyka:

                <ul class="special">
                    <li>W skład trybu potyczek wchodzi 20 scenariuszy z trzema zróżnicowanymi poziomami trudności.</li>
                    <li>Oferuje wachlarz nowych rozwiązań, niespotykanych w innych modyfikacjach.</li>
                    <li>Dialogi posiadają polski dubbing.</li>
                    <li>Część map posiada własne zestawy osiągnięć.</li>
                </ul>
            </p>

            <b-img src="/storage/images/about/multiplayer-pl.jpg" fluid class="mt-5"></b-img>

            <p class="mt-2">
                Tryb sieciowy został rozbudowany względem podstawowej wersji gry.
                Składa się z dwóch typów: rywalizacji pomiędzy graczami oraz trybu kooperacji, gdzie gracze wspólnie mogą zmierzyć się z SI.
            </p>

            <p class="mt-2">
                Charakterystyka:

                <ul class="special">
                    <li>Tryb sieciowy oferuje 20 map - 16 dla trybu rywalizacji oraz 4 dla trybu kooperacji.</li>
                    <li>Obserwator gry otrzymał kilka nowych funkcji względem oryginału, takich jak: podgląd surowców graczy czy informacje o meczu w trakcie gry.</li>
                    <li>Dostępne są zarówno klasyczne mapy, znane z podstawowej wersji gry jak i zupełnie nowe, stworzone specjalnie na potrzeby modyfikacji.</li>
                    <li>Mapy w trybie rywalizacji oferują 3 podstawowe zasady gry: klasyczna bitwa, przetrwanie oraz król wzgórza.</li>
                </ul>
            </p>

            <h5 class="text-center mt-5">Pozostałe elementy</h5>

            <p class="mt-2">
                Classic GUI - modyfikacja oferuje zupełnie nowy interfejs, oparty na klasycznym interfejsie znanym ze starszych wersji gry. Stworzony w języku LUA.
            </p>

            <b-img src="/storage/images/about/menu.png" fluid class="mt-3 mb-3"></b-img>

            <p class="mt-2">
                Osiągnięcia - lista zadań i dodatkowych celów, które gracz może osiągnąć. Obecnie dostępnych jest ich ponad 100.
            </p>

            <b-img src="/storage/images/about/achivs.png" fluid class="mt-3 mb-3"></b-img>

            <p class="mt-2">
                Biografie - każda ważna postać, którą gracz może spotkać podczas gry, ma swoją własną historię, którą można odblokować po jej napotkaniu.
            </p>

            <b-img src="/storage/images/about/bio.png" fluid class="mt-3 mb-3"></b-img>

            <p class="mt-2">
                .. oraz wiele, wiele więcej. Zachęcamy każdego weterana Original War do wypróbowania naszej modyfikacji i podzielenia się swoją opinią na naszym <a class="clickable" href="https://discord.gg/APWVVWQtBW" target="_blank">Discordzie</a>.
                Modyfikacja jest dostępna za darmo, a jedynym wymaganiem do jej uruchomienia jest posiadanie zainstalowanej gry.
            </p>

            <p class="mt-2">
                Nie czekaj i zagraj już teraz!
            </p>

            <router-link :to="{ name: 'download' }">
                <b-img src="/storage/images/about/download-pl.jpg" fluid class="mt-3 mb-3"></b-img>
            </router-link>

        </article>

        <!--<div class="card-body">
            <comments section="about"/>
        </div>-->
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                language: 'getLanguageData'
            }),
        },
    }
</script>

