<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <article class="card-body" v-if="language.id === 1"> <!-- Eng -->
            <h4 class="card-title">
                {{ trans('layout.merch') }}
            </h4>

            <p class="mt-2">
                Sand of Siberia is a project being developed in many ways.
                One of them is a set of collector's items, which were created mainly as a thank you for contributions to the development of the modification.
            </p>

            <p>
                The set includes:
            </p>

            <div class="d-block ml-2">
                <div class="d-block" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-sosbox class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-compact-disc"></i> SoSBox
                        </span>

                        <span class="small">(Click to expand)</span>
                    </div>

                    <b-collapse id="collapse-sosbox" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            The SoSBox is a mini-set that includes a box containing a DVD that features the collector's version of Sand of Siberia, along with bonus files.
                            In addition, a dedicated 24-page booklet can be found in the set, presenting various, sometimes little-known facts about the creation of the project.

                            <div class="d-block mt-2">
                                This is one of the basic prizes in contests held on the Discord server.
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/sosbox.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/sosbox2.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-calendar class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-calendar-alt"></i> Calendar
                        </span>

                        <span class="small">(Click to expand)</span>
                    </div>

                    <b-collapse id="collapse-calendar" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            Calendar for 2025, in the climate of Original War. Made of quality paper and containing a set of artwork.
                            Some of the artwork comes from the Sand of Siberia modification, others from the Original War game (permission was obtained from the game publisher to use them).

                            <div class="d-block mt-2">
                                This is one of the basic prizes in contests held on the Discord server.
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/calall.jpg" fluid class="mt-2"></b-img>
                            <div class="text-center">
                                <span class="font-italic small">
                                    Preview version with watermark
                                </span>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/calendar2.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-poster class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="far fa-image"></i> Poster
                        </span>

                        <span class="small">(Click to expand)</span>
                    </div>

                    <b-collapse id="collapse-poster" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            Poster in A1 format, made in 170g paperweight. Artwork depicts characters from the game Original War
                            and was created at the special request of the author of the modification.

                            <div class="d-block mt-2">
                                This is one of the main prizes in contests held on the Discord server.
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/poster.jpg" fluid class="mt-2"></b-img>
                            <div class="text-center">
                                <span class="font-italic small">
                                    Preview version with watermark
                                </span>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/poster2.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/poster3.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-shirt class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-tshirt"></i> Clothing
                        </span>

                        <span class="small">(Click to expand)</span>
                    </div>

                    <b-collapse id="collapse-shirt" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            The Sand of Siberia clothing set includes:

                            <ul class="special mt-1">
                                <li>Short-sleeved T-shirts, available in black. Both in men's and women's versions.</li>
                                <li>Hooded sweatshirts, available in black.</li>
                            </ul>

                            <div class="d-block mt-2">
                                There are 5 special designs available.
                            </div>

                            <div class="d-block mt-2">
                                <div class="d-block">
                                    This is one of the main prizes in contests held on the Discord server.
                                </div>
                                <div class="d-block">
                                    This is also the item that each member of the team working on the project receives.
                                </div>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/shirt.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-tanks class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-truck-monster"></i> 3D Models
                        </span>

                        <span class="small">(Click to expand)</span>
                    </div>

                    <b-collapse id="collapse-tanks" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            The Sand of Siberia 3D model set includes:

                            <ul class="special mt-1">
                                <li>Model of a Russian flamethrower on a heavy chassis.</li>
                                <li>Model of Russian long rocket launcher on heavy chassis.</li>
                                <li>Model of Behemoth.</li>
                            </ul>

                            <div class="d-block mt-2">
                                <div class="d-block">
                                    This is a special prize in contests held on the Discord server.
                                </div>
                                <div class="d-block">
                                    This is also the item that a team member receives for outstanding contributions to the project.
                                </div>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/flame.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/rocket.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/beh.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>
            </div>

            <div class="d-block mt-5" style="border-bottom: 1px solid #0b76b1">
                <div v-b-toggle.collapse-unboxing class="clickable mb-2">
                    <span class="h5 ml-1">
                        <i class="fab fa-youtube"></i> Unboxing
                    </span>

                    <span class="small">(Click to expand)</span>
                </div>

                <b-collapse id="collapse-unboxing" style="border-top: 1px solid #0b76b1;">
                    <div class="d-block mt-2">
                        Courtesy of Jakes, we present an unboxing of most of the items, recorded in 2024.

                        <div class="d-block mt-2">
                            Items shown in the unboxing:

                            <ul class="special mt-1">
                                <li>
                                    Calendar for 2024
                                </li>
                                <li>
                                    SoSBox
                                </li>
                                <li>
                                    Tank model with flamethrower
                                </li>
                                <li>
                                    Two T-shirts with different designs
                                </li>
                                <li>
                                    Hooded sweatshirt
                                </li>
                                <li>
                                    Long-rocket tank model.
                                </li>
                                <li>
                                    Behemoth's model.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="d-block mt-3">
                        <b-embed
                            type="iframe"
                            aspect="16by9"
                            src="https://www.youtube.com/embed/Fr6cko4yS3w?rel=0"
                            allowfullscreen
                        ></b-embed>
                    </div>

                    <div class="d-block mt-3">
                        <b-embed
                            type="iframe"
                            aspect="16by9"
                            src="https://www.youtube.com/embed/Na4btrdY1zQ?rel=0"
                            allowfullscreen
                        ></b-embed>
                    </div>
                </b-collapse>
            </div>
        </article>

        <article class="card-body" v-else> <!-- Polski -->
            <h4 class="card-title">
                {{ trans('layout.merch') }}
            </h4>

            <p class="mt-2">
                Sand of Siberia to projekt rozwijany na wielu płaszczyznach.
                Jedną z nich jest zestaw przedmiotów kolekcjonerskich, które zostały stworzone głównie w ramach podziękowań za wkład w rozwój modyfikacji.
            </p>

            <p>
                W skład zestawu wchodzą:
            </p>

            <div class="d-block ml-2">
                <div class="d-block" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-sosbox class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-compact-disc"></i> SoSBox
                        </span>

                        <span class="small">(Kliknij aby rozwinąć)</span>
                    </div>

                    <b-collapse id="collapse-sosbox" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            SoSBox to mini-zestaw w skład którego wchodzi pudełko zawierające płytę DVD, na której znajduje się wersja kolekcjonerska Sand of Siberia, wraz z dodatkowymi plikami.
                            Dodatkowo, w zestawie można znaleźć dedykowany, 24-stronicowy booklet, przedstawiający różne, niekiedy mało znane fakty na temat powstawania projektu.

                            <div class="d-block mt-2">
                                Jest to jedna z podstawowych nagród w konkursach organizowanych na serwerze Discord.
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/sosbox.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/sosbox2.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-calendar class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-calendar-alt"></i> Kalendarz
                        </span>

                        <span class="small">(Kliknij aby rozwinąć)</span>
                    </div>

                    <b-collapse id="collapse-calendar" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            Kalendarz na 2025 rok, w klimacie Original War. Wykonany z jakościowego papieru oraz zawierający zestaw artworków.
                            Część z artworków pochodzi z modyfikacji Sand of Siberia, pozostałe z gry Original War (została uzyskana zgody wydawcy gry na ich wykorzystanie).

                            <div class="d-block mt-2">
                                Jest to jedna z podstawowych nagród w konkursach organizowanych na serwerze Discord.
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/calall.jpg" fluid class="mt-2"></b-img>
                            <div class="text-center">
                                <span class="font-italic small">
                                    Wersja poglądowa ze znakiem wodnym
                                </span>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/calendar2.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-poster class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="far fa-image"></i> Plakat
                        </span>

                        <span class="small">(Kliknij aby rozwinąć)</span>
                    </div>

                    <b-collapse id="collapse-poster" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            Plakat w formacie A1, wykonany w gramaturze 170g z folia bł. jednostronnie. Artwork przedstawia postaci z gry Original War
                            i został stworzony na specjalne zamówienie autora modyfikacji.

                            <div class="d-block mt-2">
                                Jest to jedna z głównych nagród w konkursach organizowanych na serwerze Discord.
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/poster.jpg" fluid class="mt-2"></b-img>
                            <div class="text-center">
                                <span class="font-italic small">
                                    Wersja poglądowa ze znakiem wodnym
                                </span>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/poster2.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/poster3.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-shirt class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-tshirt"></i> Odzież
                        </span>

                        <span class="small">(Kliknij aby rozwinąć)</span>
                    </div>

                    <b-collapse id="collapse-shirt" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            W zestaw odzieży Sand of Siberia wchodzą:

                            <ul class="special mt-1">
                                <li>Koszulki z krótkim rękawem, dostępne w kolorze czarnym. Zarówno w wersji męskiej jak i żeńskiej.</li>
                                <li>Bluzy z kaputrem, dostępne w kolorze czarnym.</li>
                            </ul>

                            <div class="d-block mt-2">
                                Dostępnych jest 5 specjalnych wzorów w wymiarach M-2XL.
                            </div>

                            <div class="d-block mt-2">
                                <div class="d-block">
                                    Jest to jedna z głównych nagród w konkursach organizowanych na serwerze Discord.
                                </div>
                                <div class="d-block">
                                    Jest to również przedmiot który otrzymuje każdy z członków zespołu pracującego nad projektem.
                                </div>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/shirt.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-tanks class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-truck-monster"></i> Modele
                        </span>

                        <span class="small">(Kliknij aby rozwinąć)</span>
                    </div>

                    <b-collapse id="collapse-tanks" accordion="collection" style="border-top: 1px solid #0b76b1; padding: 3px;">
                        <div class="d-block">
                            W zestaw modeli 3D Sand of Siberia wchodzą:

                            <ul class="special mt-1">
                                <li>Model rosyjskiego miotacza ognia na ciężkim podwoziu. </li>
                                <li>Model rosyjskiej wyrzutni długich rakiet na ciężkim podwoziu. </li>
                                <li>Model Behemoth'a. </li>
                            </ul>

                            <div class="d-block mt-2">
                                <div class="d-block">
                                    Jest to specjalna nagroda w konkursach organizowanych na serwerze Discord.
                                </div>
                                <div class="d-block">
                                    Jest to również przedmiot który otrzymuje członek zespołu za wybitny wkład w prace nad projektem.
                                </div>
                            </div>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/flame.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/rocket.jpg" fluid class="mt-2"></b-img>
                        </div>

                        <div class="d-block mt-2">
                            <b-img src="/storage/images/merch/beh.jpg" fluid class="mt-2"></b-img>
                        </div>
                    </b-collapse>
                </div>
            </div>

            <div class="d-block mt-5">
                <div class="mb-2">
                    <span class="h5 ml-1">
                        <i class="fas fa-info-circle"></i> Dodatkowe informacje
                    </span>
                </div>

                <p class="mt-2">
                    Zamów kolekcjonerski kalendarz lub plakat z limitowanej kolekcji Sand of Siberia.
                    W ofercie tylko kilka egzemplarzy.
                    Skorzystaj z opcji Wyślij zapytanie lub napisz na adres: <a href="mailto:contact@sand-of-siberia.pl">contact@sand-of-siberia.pl</a>.
                    Wysyłka wyłącznie na terenie Polski. Koszt przesyłki: 18,00zł.
                </p>

                <div class="d-block" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-price class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fas fa-money-check-alt"></i> Cennik
                        </span>

                        <span class="small">(Kliknij aby rozwinąć)</span>
                    </div>

                    <b-collapse id="collapse-price" style="border-top: 1px solid #0b76b1;">
                        <div class="d-block mt-2">
                            <b-table dark striped hover sort-icon-left
                                     :items="prices"
                                     :fields="pricesFields"
                                     id="pricesTable"
                            >
                            </b-table>

                            <div class="d-block mt-1">
                                Koszt przesyłki to 18 zł.
                            </div>

                            <div class="d-block mt-3 mb-3">
                                <b-button v-on:click="showModalContact()" class="mt-1">{{ trans('layout.order') }}</b-button>
                            </div>
                        </div>
                    </b-collapse>
                </div>

                <div class="d-block mt-3" style="border-bottom: 1px solid #0b76b1">
                    <div v-b-toggle.collapse-unboxing class="clickable mb-2">
                        <span class="h5 ml-1">
                            <i class="fab fa-youtube"></i> Unboxing zestawu
                        </span>

                        <span class="small">(Kliknij aby rozwinąć)</span>
                    </div>

                    <b-collapse id="collapse-unboxing" style="border-top: 1px solid #0b76b1;">
                        <div class="d-block mt-2">
                            Dzięki uprzejmości Jakesa, prezentujemy unboxing większości z przedmiotów, zarejestrowany w 2024 roku.

                            <div class="d-block mt-2">
                                Przedmioty przedstawione na unboxing'u:

                                <ul class="special mt-1">
                                    <li>
                                        Kalendarz na rok 2024
                                    </li>
                                    <li>
                                        SoSBox
                                    </li>
                                    <li>
                                        Model czołgu z miotaczem ognia
                                    </li>
                                    <li>
                                        Koszulki z dwoma wzorami
                                    </li>
                                    <li>
                                        Bluza z kaputrem
                                    </li>
                                    <li>
                                        Model czołgu z długą rakietą
                                    </li>
                                    <li>
                                        Model Behemoth'a
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="d-block mt-3">
                            <b-embed
                                type="iframe"
                                aspect="16by9"
                                src="https://www.youtube.com/embed/Fr6cko4yS3w?rel=0"
                                allowfullscreen
                            ></b-embed>
                        </div>

                        <div class="d-block mt-3">
                            <b-embed
                                type="iframe"
                                aspect="16by9"
                                src="https://www.youtube.com/embed/Na4btrdY1zQ?rel=0"
                                allowfullscreen
                            ></b-embed>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                pricesFields: [
                    {
                        key: 'name',
                        label: this.trans('layout.modal.name'),
                    },
                    {
                        key: 'price',
                        label: this.trans('layout.price'),
                    }
                ],
                prices: [
                    {
                        name: this.trans('layout.calendar'),
                        price: '30 zł',
                    },
                    {
                        name: 'SoSBox',
                        price: '80 zł',
                    },
                    {
                        name: this.trans('layout.poster'),
                        price: '115 zł',
                    }
                ]
            };
        },
        computed: {
            ...mapGetters({
                language: 'getLanguageData'
            }),
        },
        methods: {
            showModalContact: function () {
                this.$modal.show('contactModal');
            }
        }
    }
</script>

