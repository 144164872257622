<template>
    <div>
        <div class="row">
            <!-- Nav Top Buttons -->
            <div class="col-lg-4 col-md-4">
                <div class="btn-group btn-group-admin">
                    <b-button type="button" class="btn-admin" v-on:click="onAddNews()">
                        Dodaj news
                    </b-button>
                    <b-button type="button" class="btn-admin" v-on:click="onAddNewsCategory()">
                        Dodaj kategorię
                    </b-button>
                </div>
            </div>
        </div>

        <b-card no-body class="bg-dark-blue-2 border-card">
            <b-tabs pills card>
                <b-tab title="Newsy" active>
                    <b-card-text>
                        <b-table dark striped hover sort-icon-left
                                 :items="news.list"
                                 :fields="news.table.fields"
                                 :per-page="news.table.perPage"
                                 :current-page="news.table.currentPage"
                                 id="newsListTable"
                                 v-if="news.list.length"
                        >
                            <template slot="index" slot-scope="data">
                                {{ (news.table.currentPage - 1) * news.table.perPage + data.index + 1 }}
                            </template>

                            <template v-slot:cell(name_en)="data">
                                {{ data.item.loc[1].name }}
                            </template>

                            <template v-slot:cell(name_pl)="data">
                                {{ data.item.loc[2].name }}
                            </template>

                            <template v-slot:cell(category)="data">
                                {{ data.item.category.name_pl }}
                            </template>

                            <template v-slot:cell(public)="data">
                                <span v-if="data.item.public === 1">
                                    Tak
                                </span>

                                <span v-else>
                                    Nie
                                </span>
                            </template>

                            <template v-slot:cell(options)="data">
                                <b-button class="btn-admin" v-on:click="onEditNews(data.item)">
                                    Edytuj
                                </b-button>

                                <b-button class="btn-admin" v-on:click="onDeleteNews(data.item)">
                                    Usuń
                                </b-button>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>

                <b-tab title="Kategorie">
                    <b-card-text>
                        <b-table dark striped hover sort-icon-left
                                 :items="category.list"
                                 :fields="category.table.fields"
                                 :per-page="category.table.perPage"
                                 :current-page="category.table.currentPage"
                                 id="categoryListTable"
                                 v-if="category.list.length"
                        >
                            <template v-slot:cell(options)="data">
                                <b-button class="btn-admin" v-on:click="onEditCategory(data.item)">
                                    Edytuj
                                </b-button>

                                <b-button class="btn-admin" v-on:click="onDeleteCategory(data.item)">
                                    Usuń
                                </b-button>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>

        <modal name="newsModal" scrollable height="auto" width="99%" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('newsModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-icon mr-2" v-on:click="onFormResetNews()">
                        <i class="fa fa-trash"/>
                    </div>

                    <div class="modal-title">
                        News
                    </div>

                    <b-card no-body class="card bg-dark-blue-2 border-card">
                        <b-tabs card>
                            <b-tab title="Angielski" active>
                                <b-card-text>
                                    <b-form v-on:reset="onFormResetNewsEn()">

                                        <b-form-group
                                            id="input-group-title-en"
                                            label="Nazwa"
                                            label-for="input-title-en"
                                        >
                                            <b-form-input
                                                id="input-title-en"
                                                v-model="news.name.eng"
                                                type="text"
                                                placeholder="Podaj tytuł"
                                            />
                                        </b-form-group>

                                        <b-form-group
                                            id="input-group-category-en"
                                            label="Kategoria"
                                            label-for="input-category-en"
                                        >
                                            <b-select v-model="news.category"
                                                      :options="news.categories"
                                                      id="input-category-en"
                                            />
                                        </b-form-group>

                                        <b-form-group
                                            id="input-group-public-en"
                                            label="Status"
                                            label-for="input-public-en"
                                        >
                                            <b-form-checkbox
                                                id="input-public-en"
                                                v-model="news.isPublic"
                                                name="checkbox-1"
                                                :value="1"
                                                :unchecked-value="0"
                                            >
                                                Widoczny
                                            </b-form-checkbox>
                                        </b-form-group>

                                        <el-tiptap
                                            v-model="news.content.eng"
                                            :extensions="extensions"
                                            style="height: 50vh;"
                                        />

                                        <b-btn-group class="mt-2">
                                            <b-button type="reset" class="btn-admin">
                                                <i class="fa fa-trash"/> Wyczyść
                                            </b-button>

                                            <b-button type="button" class="btn-admin" v-on:click="onPreviewNews(news.name.eng, news.content.eng)">
                                                <i class="fa fa-eye"/> Podgląd
                                            </b-button>
                                        </b-btn-group>
                                    </b-form>
                                </b-card-text>
                            </b-tab>

                            <b-tab title="Polski">
                                <b-card-text>
                                    <b-form v-on:reset="onFormResetNewsPl()">

                                        <b-form-group
                                            id="input-group-title-pl"
                                            label="Nazwa"
                                            label-for="input-title-pl"
                                        >
                                            <b-form-input
                                                id="input-title-pl"
                                                v-model="news.name.pl"
                                                type="text"
                                                placeholder="Podaj tytuł"
                                            />
                                        </b-form-group>

                                        <b-form-group
                                            id="input-group-category-pl"
                                            label="Kategoria"
                                            label-for="input-category-pl"
                                        >
                                            <b-select v-model="news.category"
                                                      :options="news.categories"
                                                      id="input-category-pl"
                                            />
                                        </b-form-group>

                                        <b-form-group
                                            id="input-group-public-pl"
                                            label="Status"
                                            label-for="input-public-pl"
                                        >
                                            <b-form-checkbox
                                                id="input-public-pl"
                                                v-model="news.isPublic"
                                                name="checkbox-1"
                                                :value="1"
                                                :unchecked-value="0"
                                            >
                                                Widoczny
                                            </b-form-checkbox>
                                        </b-form-group>

                                        <el-tiptap
                                            v-model="news.content.pl"
                                            :extensions="extensions"
                                            style="height: 50vh;"
                                        />

                                        <b-btn-group class="mt-2">
                                            <b-button type="reset" class="btn-admin">
                                                <i class="fa fa-trash"/> Wyczyść
                                            </b-button>

                                            <b-button type="button" class="btn-admin" v-on:click="onPreviewNews(news.name.pl, news.content.pl, news.category)">
                                                <i class="fa fa-eye"/> Podgląd
                                            </b-button>
                                        </b-btn-group>
                                    </b-form>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>

                    <b-button class="btn-admin btn-block mt-2" size="lg" :disabled="canSubmitNews" v-on:click="onSubmitNews()">
                        <i class="fa fa-save"/> Zapisz
                    </b-button>
                </div>
            </div>
        </modal>

        <modal name="categoryModal" scrollable adaptive height="auto" width="50%" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('categoryModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-icon mr-2" v-on:click="onFormResetCategory()">
                        <i class="fa fa-trash"/>
                    </div>

                    <div class="modal-title">
                        Kategoria
                    </div>

                    <b-card no-body class="card bg-dark-blue-2 border-card">
                        <b-tabs card>
                            <b-tab title="Angielski" active>
                                <b-card-text>
                                    <b-form v-on:reset="onFormResetCategoryEn()">

                                        <b-form-group
                                            id="input-group-title-category-en"
                                            label="Nazwa"
                                            label-for="input-title-category-en"
                                        >
                                            <b-form-input
                                                id="input-title-en"
                                                v-model="category.name.eng"
                                                type="text"
                                                placeholder="Podaj nazwę"
                                            />
                                        </b-form-group>

                                        <b-btn-group class="mt-2">
                                            <b-button type="reset" class="btn-admin">
                                                <i class="fa fa-trash"/> Wyczyść
                                            </b-button>
                                        </b-btn-group>
                                    </b-form>
                                </b-card-text>
                            </b-tab>

                            <b-tab title="Polski">
                                <b-card-text>
                                    <b-form v-on:reset="onFormResetCategoryPl()">

                                        <b-form-group
                                            id="input-group-title-category-pl"
                                            label="Nazwa"
                                            label-for="input-title-category-pl"
                                        >
                                            <b-form-input
                                                id="input-title-en"
                                                v-model="category.name.pl"
                                                type="text"
                                                placeholder="Podaj nazwę"
                                            />
                                        </b-form-group>

                                        <b-btn-group class="mt-2">
                                            <b-button type="reset" class="btn-admin">
                                                <i class="fa fa-trash"/> Wyczyść
                                            </b-button>
                                        </b-btn-group>
                                    </b-form>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>

                    <b-button class="btn-admin btn-block mt-2" size="lg" v-on:click="onSubmitCategory()" :disabled="canSubmitCategory">
                        <i class="fa fa-save"/> Zapisz
                    </b-button>
                </div>
            </div>
        </modal>

        <modal name="previewModal" scrollable height="auto" width="90%" class="always-top-1" v-on:closed="onBackToEditor()">
            <div class="card bg-dark-blue-2 border-card">

                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('previewModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ preview.title }}
                    </div>

                    <section v-html="preview.content"/>

                </div>

            </div>
        </modal>

    </div>
</template>

<script>

    import {
        Doc,
        Text,
        Paragraph,
        Heading,
        Bold,
        Underline,
        Italic,
        Strike,
        // Link,
        // Image,
        Blockquote,
        CodeBlock,
        ListItem,
        BulletList,
        OrderedList,
        TodoItem,
        TodoList,
        TextAlign,
        TextColor,
        TextHighlight,
        Indent,
        LineHeight,
        HardBreak,
        HorizontalRule,
        TrailingNode,
        FormatClear,
        History,
    } from 'element-tiptap';

    import ImageUrl from '../../ImageUrl';
    import LinkUrl from '../../LinkUrl';
    import EmbedUrl from '../../EmbedUrl';
    export default {
        data() {
            return {
                extensions: [
                    new Doc(),
                    new Text(),
                    new Paragraph(),
                    new Heading({ level: 5 }),
                    new TextColor(),
                    new TextHighlight(),
                    new Bold(),
                    new Underline(),
                    new Italic(),
                    new Strike(),
                    // new Link(),
                    new ImageUrl(),
                    new LinkUrl(),
                    new EmbedUrl(),
                    // new Image(),
                    new Blockquote(),
                    new CodeBlock(),
                    new TextAlign(),
                    new LineHeight(),
                    new ListItem(),
                    new BulletList(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Indent(),
                    new HardBreak(),
                    new HorizontalRule(),
                    new TrailingNode(),
                    new FormatClear(),
                    new History(),
                ],
                lastEditedNews: null,
                news: {
                    id: 0,
                    name: {
                        eng: '',
                        pl: '',
                    },
                    content: {
                        eng: '',
                        pl: '',
                    },
                    isPublic: 0,
                    category: 0,
                    categories: [],
                    list: {},
                    table: {
                        perPage: 20,
                        currentPage: 1,
                        fields: [
                            {
                                key: 'id',
                                sortable: true,
                                label: '#',
                            },
                            {
                                key: 'name_en',
                                label: 'Nazwa EN',
                            },
                            {
                                key: 'name_pl',
                                label: 'Nazwa PL',
                            },
                            {
                                key: 'category',
                                label: 'Kategoria',
                            },
                            {
                                key: 'public',
                                label: 'Widoczny',
                            },
                            {
                                key: 'options',
                                label: 'Opcje',
                            }
                        ]
                    }
                },
                category: {
                    id: 0,
                    name: {
                        eng: '',
                        pl: '',
                    },
                    list: {},
                    table: {
                        perPage: 20,
                        currentPage: 1,
                        fields: [
                            {
                                key: 'id',
                                sortable: true,
                                label: '#',
                            },
                            {
                                key: 'name',
                                label: 'Nazwa (EN)',
                            },
                            {
                                key: 'name_pl',
                                label: 'Nazwa (PL)',
                            },
                            {
                                key: 'updated_at',
                                label: 'Ostatnia aktualizacja',
                                sortable: true,
                            },
                            {
                                key: 'options',
                                label: 'Opcje',
                            }
                        ]
                    }
                },
                preview: {
                    title: '',
                    content: '',
                    category: '',
                }
            }
        },
        computed: {
            canSubmitNews() {
                return (this.news.content.eng.length * this.news.content.pl.length *
                        this.news.name.eng.length * this.news.name.pl.length * this.news.category) === 0;
            },
            canSubmitCategory() {
                return (this.category.name.eng.length * this.category.name.pl.length) === 0;
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData: function() {
                let self = this;

                axios.get('/admin/news/get/').then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.news.list = data.news;
                        self.category.list = data.categories;

                        for (let i = 0; i < data.categories.length; i++) {
                            self.news.categories[i] = {
                                value: data.categories[i].id,
                                text: data.categories[i].name_pl,
                            }
                        }
                    }
                });
            },
            onBackToEditor: function() {
                let id = this.lastEditedNews;

                if (!id)
                    return;

                let list = this.news.list;

                for (let i = 0; i < list.length; i++) {
                    if (list[i].id === id) {
                        this.onEditNews(list[i]);
                        break;
                    }
                }
            },
            onAddNews: function() {
                this.onFormResetNews();
                this.$modal.show('newsModal');
            },
            onAddNewsCategory: function() {
                this.$modal.show('categoryModal');
            },
            onEditNews: function(news) {
                this.$modal.show('newsModal');

                this.news.id = news.id;
                this.news.category = news.id_news_category;
                this.news.name.eng = news.loc[1].name;
                this.news.name.pl = news.loc[2].name;
                this.news.content.eng = news.loc[1].text;
                this.news.content.pl = news.loc[2].text;
                this.news.isPublic = news.public;
            },
            onDeleteNews: function(news) {
                let self = this;
                let data = {
                    'id' : news.id,
                };

                axios.post('/admin/news/delete/news/', data).then(function(response) {
                    data = response.data;

                    if (data.success) {
                        self.onFormResetNews();
                        self.getData();
                    }
                });
            },
            onEditCategory: function(category) {
                this.$modal.show('categoryModal');

                this.category.id = category.id;
                this.category.name.eng = category.name;
                this.category.name.pl = category.name_pl;
            },
            onDeleteCategory: function(category) {
                let self = this;
                let data = {
                    'id' : category.id,
                };

                axios.post('/admin/news/delete/category/', data).then(function(response) {
                    data = response.data;

                    if (data.success) {
                        self.onFormResetCategory();
                        self.getData();
                    }
                });
            },
            onFormResetNews: function() {
                this.news.id = 0;
                this.news.content.eng = '';
                this.news.name.eng = '';
                this.news.content.pl = '';
                this.news.name.pl = '';
                this.news.isPublic = 0;
            },
            onFormResetNewsEn: function() {
                this.news.content.eng = '';
                this.news.name.eng = '';
            },
            onFormResetNewsPl: function() {
                this.news.content.pl = '';
                this.news.name.pl = '';
            },
            onPreviewNews: function(title, content) {
                this.preview = {
                    'title' : title,
                    'content' : content
                };

                this.lastEditedNews = this.news.id;

                this.onSubmitNews();
                this.$modal.show('previewModal');
            },
            onFormResetCategory: function() {
                this.category.id = 0;
                this.category.name.eng = '';
                this.category.name.pl = '';
            },
            onFormResetCategoryEn: function() {
                this.category.name.eng = '';
            },
            onFormResetCategoryPl: function() {
                this.category.name.pl = '';
            },
            onSubmitNews: function() {
                let self = this;
                let data = {
                    id : self.news.id,
                    category: self.news.category,
                    name_en : self.news.name.eng,
                    name_pl : self.news.name.pl,
                    content_en : self.news.content.eng,
                    content_pl : self.news.content.pl,
                    isPublic : self.news.isPublic,
                };

                axios.post('/admin/news/save/news/', data).then(function(response) {
                    data = response.data;

                    if (data.success) {
                        self.$modal.hide('newsModal');
                        self.onFormResetNews();
                        self.getData();
                    }
                });
            },
            onSubmitCategory: function() {
                let self = this;
                let data = {
                    id : this.category.id,
                    name_en : this.category.name.eng,
                    name_pl : this.category.name.pl,
                };

                axios.post('/admin/news/save/category/', data).then(function(response) {
                    data = response.data;

                    if (data.success) {
                        self.$modal.hide('categoryModal');
                        self.onFormResetCategory();
                        self.getData();
                    }
                });
            }
        }
    }
</script>
