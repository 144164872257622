<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <article class="card-body" v-if="language.id === 1"> <!-- Eng -->
            <h4 class="card-title">
                {{ trans('layout.about') }}
            </h4>

            <p>
                <b>Sand of Siberia</b> it is a game modification for <a href="https://pl.wikipedia.org/wiki/Original_War" target="_blank">Original War</a>.
            </p>

            <h5>
                Introduction
            </h5>

            <p>
                The modification is to include four campaigns, several maps of skirmish maps and cooperative maps.
                Its goal is to maximize the potential of the game itself.
                In many respects, the modification creators use the notes left by the Original War authors.
                The project itself is of a non-profit nature and you can report any willingness to cooperate.
            </p>


            <div class="pt-3 pb-3">
                <h5>
                    History
                </h5>

                <p>
                    The beginnings of the project date back to 2011, when work on the Arab campaign began.
                    The player was to direct to tells the story of Louis Schulz, a mercenary at the service of Sheikhs.
                    The campaign itself was to be canonical compared to the original story and depicts the fate of the war from the perspective of the mercenary.
                </p>

                <b-img src="/storage/images/ScreenShot023.jpg" fluid class="mt-2"></b-img>

                <div class="text-center">
                    <span class="font-italic small">
                        The first Arab mission - 2011
                    </span>
                </div>

                <p class="pt-3 pb-3">
                    In 2013, once with the project <a href="https://forum.original-war.net/viewtopic.php?f=28&t=3847" target="_blank">Fight With Bot</a> the concept of map-battles has emerged.
                    Based on the notes of the game authors and own ideas, I managed to create the first few skirmishes, among others: Gamma or Operation Omicron.
                </p>

                <p>
                    On December 24, 2015, the modification was made publicly available. Since then, the project has been constantly expanded with new content.
                </p>

                <p class="pt-3 pb-3">
                    In May 2018, one of the project partners - Nefarem - proposed the creation of a refreshed American campaign.
                    The player's interest was surprisingly large, which is why we decided to focus all our forces on this aspect of the project.
                </p>

                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/UmyfW_AW7F0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            <div class="pt-3 pb-3">
                <h5>
                    Contents
                </h5>

                <p>
                    As already mentioned, the modification will ultimately include 5 playable campaigns: America (refreshed), Soviet (refreshed), Arab, Ares and Alliances.
                    At the moment, the developers are mainly focusing on the Russian campaign - though on <a href="https://www.youtube.com/channel/UCEqQ6d1-8-keTa_9-u6aqqA" target="_blank"> YouTube </a> can be found gameplay from the American and Ares campaigns.
                </p>

                <p>
                    In addition, the modification includes several maps of single player battles and two cooperative maps.
                </p>

                <p>
                    Some maps use a proprietary script to improve the operation of computer opponents.
                </p>
            </div>

            <div class="pt-3 pb-3">
                <h5>
                    Media
                </h5>

                <p>
                    For several years, the modification has been systematically presented on YouTube thanks to people such as
                    <a href="https://www.youtube.com/user/JakesPlayer">Jakes</a>,
                    <a href="https://www.youtube.com/channel/UCidqU2NQap0m2DnhnkF79aw">Kvantovy</a>,
                    <a href="https://www.youtube.com/channel/UCXaSYYWvb_0yVyOBOFK_68g">Livid</a> or
                    <a href="https://www.youtube.com/channel/UCpnfEu1hwFt9tj0b95LombA">Bezimienny111</a>.

                    The main goal of this procedure is to refute the myth of "dead game" and encourage players' to return.
                </p>

                <p>
                    Some of the materials can be found <a href="/videos" target="_blank">here</a>.
                </p>

                <b-img src="/storage/images/mainmenu.jpg" fluid class="mt-2"></b-img>

                <div class="text-center">
                    <span class="font-italic small">
                        Main Menu - 2021
                    </span>
                </div>
            </div>

            <div class="pt-3 pb-3">
                <h5>
                    The team
                </h5>

                <p>
                    Over the years, dozens of people have passed through the project, which has added their brick - from reliable tests, by comparing their voice to dubbing, to ready mission projects.
                    At this point, I would like to thank everyone who has devoted their valuable time to the development of this project.
                </p>

                <div>
                    All thanks go to:

                    <div class="d-block mt-3">
                        <h5>Main Team</h5>

                        <ul class="special">
                            <li>Mikołaj "Nefarem" W.</li>
                            <li>Adam "Prażanin" O.</li>
                            <li>Rafał "Ralf32" A.</li>
                            <li>Jakub "Kao" R.</li>
                            <li>Daniel "Mietek" R.</li>
                            <li>Jan "Jakes" Cz.</li>
                            <li>Mateusz "Gelwe" F.</li>
                            <li>Jacek "Maki Roll" Ł.</li>
                            <li>Przemysław "Gogeta103" G.</li>
                            <li>Michał "Siwson" Sobol</li>
                            <li>Hubert "Thangir" Ś.</li>
                            <li>Alexander "Gwren" P. Sánchez</li>
                            <li>"Kowass"</li>
                            <li>Karol "MasterKiller" D.</li>
                        </ul>
                    </div>

                    <div class="d-block mt-3">
                        <h5>Dubbing</h5>

                        <ul class="special">
                            <li>Heike Steyer - Joanna "Maki" Grzegorczyk</li>
                            <li>Givi Maisuradze - Kamil "Bitewny Biurokrata" B.</li>
                            <li>Kaia Krizman - Gabriela "Gabrielle Middleton" M.</li>
                            <li>Dr. Aviradze - Kacper "PanSiwy" P.</li>
                            <li>Burłak - Bartosz "Iliach" Sadkowski</li>
                            <li>Mia Mathiassen - Agata "Kapitan" Sutkowska</li>
                            <li>TID_Credits_Arabian_Soldier - Monika "Tosia" Witaszczyk</li>
                            <li>Kurt Schmidt - Damian Czarnocki</li>
                            <li>Johnny Harris - "PiastBoromir"</li>
                            <li>Omar Ali - Jan "Jakes" Cz.</li>
                            <li>Mike Turssen - Adam "Prażanin" O.</li>
                            <li>American Soldier - Anna "Asirra" Pisarek</li>
                            <li>Robert Farmer, Krastyo Markov, Abdul Shariff - Michał "Siwson" Sobol</li>
                            <li>Olaf Larsson - Bartłomiej Zaryczny</li>
                            <li>Sophia Spyros - Kinga Zaryczna</li>
                            <li>Rolf Bergkampf - "MocnyBrowarek"</li>
                            <li>Gen. Morozov - "Mart"</li>
                            <li>Nia Maisuradze - "Lutra"</li>
                            <li>Muchin - "0rzeł"</li>
                            <li>Arabian Soldier - Tryt</li>
                        </ul>
                    </div>

                    <div class="d-block mt-3">
                        <h5>Special thanks</h5>

                        <ul class="special">
                            <li>Stuart "Stucuk" C.</li>
                            <li>Petr "Sali" S.</li>
                            <li>Paweł "Kvantovy" S.</li>
                            <li>Rafał "MarkedOne" M.</li>
                            <li>Jan "Jakes" Cz.</li>
                            <li>Konstanty "Livid" K.</li>
                            <li>"Bezimienny111"</li>
                            <li>"Lutel"</li>
                            <li>"MocnyBrowarek"</li>
                            <li>"Pecetowo"</li>
                            <li>"Seri Ray"</li>
                            <li>Artur "arturc20" C.</li>
                        </ul>
                    </div>

                    <p class="pt-2">
                        Once again, many thanks. Without you, this project would not have been possible :)
                    </p>

                </div>
            </div>
        </article>

        <article class="card-body" v-else> <!-- Polski -->
            <h4 class="card-title">
                {{ trans('layout.about') }}
            </h4>

            <p>
                <b>Sand of Siberia</b> to modyfikacja do gry <a href="https://pl.wikipedia.org/wiki/Original_War" target="_blank">Original War</a>.
            </p>

            <h5>
                Wstęp
            </h5>

            <p>
                Docelowo modyfikacja ma zawierać 4 kampanie, kilkanaście map-potyczek oraz kilka map kooperacyjnych.
                Jej celem jest maksymalne wykorzystanie potencjału, jaki drzemie w samej grze.
                W wielu aspektach, twórcy modyfikacji wspomagają się notatkami pozostawionymi przez autorów Original War.
                Sam projekt ma charakter non-profit i każdy może zgłosić chęć współpracy.
            </p>


            <div class="pt-3 pb-3">
                <h5>
                    Historia
                </h5>

                <p>
                    Początki projektu sięgają roku 2011, kiedy to rozpoczęły się pracę nad kampanią arabską. Gracz miał pokierować losami Louisa Schulza, najemnika na usługach szejków.
                    Sama kampania miała być kanoniczna względem oryginalnej fabuły i przedstawiać losy wojny z perspektywy najemników.
                </p>

                <b-img src="/storage/images/ScreenShot023.jpg" fluid class="mt-2"></b-img>

                <div class="text-center">
                    <span class="font-italic small">
                        Pierwsza misja arabska - rok 2011
                    </span>
                </div>

                <p class="pt-3 pb-3">
                    W roku 2013, w raz z projektem <a href="https://forum.original-war.net/viewtopic.php?f=28&t=3847" target="_blank">Fight With Bot</a> pojawiła się koncepcja map-potyczek.
                    Bazując na zapiskach twórców gry oraz własnych pomysłach udało się stworzyć kilka pierwszych potyczek, były to m.in. Gamma czy Operacja Omikron.
                </p>

                <p>
                    24 grudnia 2015 roku, modyfikacja została udostępniona publicznie. Od tego czasu projekt jest wciąż rozbudowywany o nowe treści.
                </p>

                <p class="pt-3 pb-3">
                    W maju 2018 roku, jeden z współtwórców projektu - Nefarem, zaproponował stworzenie odświeżonej kampanii amerykańskiej.
                    Zainteresowanie graczy było zaskakująco duże, dlatego też postanowiliśmy skupić wszystkie siły na tym aspekcie projektu.
                </p>

                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/UmyfW_AW7F0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            <div class="pt-3 pb-3">
                <h5>
                    Treść
                </h5>

                <p>
                    Jak już zostało wspomniane, docelowo modyfikacja ma zawierać 5 grywalnych kampanii: amerykańską (odświeżona), radziecką (odświeżona), arabską, Ares oraz przymierza.
                    Na ten moment, twórcy skupiają się głównie na kampanii rosyjskiej - choć na <a href="https://www.youtube.com/channel/UCEqQ6d1-8-keTa_9-u6aqqA" target="_blank">YouTubie</a> można znaleźć gameplay z kampanii amerykańskiej oraz kampanii Ares.
                </p>

                <p>
                    Ponadto, modyfikacja zawiera kilkanaście map-potyczek dla jednego gracza oraz dwie mapy kooperacyjne.
                </p>

                <p>
                    Część map korzysta z autorskiego skryptu usprawniającego działanie przeciwników komputerowych.
                </p>
            </div>

            <div class="pt-3 pb-3">
                <h5>
                    Media
                </h5>

                <p>
                    Od kilku lat, modyfikacja jest systematycznie prezentowana w serwisie YouTube dzieki osobom takim jak
                    <a href="https://www.youtube.com/user/JakesPlayer">Jakes</a>,
                    <a href="https://www.youtube.com/channel/UCidqU2NQap0m2DnhnkF79aw">Kvantovy</a>,
                    <a href="https://www.youtube.com/channel/UCXaSYYWvb_0yVyOBOFK_68g">Livid</a> czy
                    <a href="https://www.youtube.com/channel/UCpnfEu1hwFt9tj0b95LombA">Bezimienny111</a>.

                    Głównym celem tego zabiegu jest obalenie mitu "martwej gry" oraz zachęcenie graczy do powrotu.
                </p>

                <p>
                    Część materiałów można znaleźć <a href="/videos" target="_blank">tutaj</a>.
                </p>

                <b-img src="/storage/images/mainmenu.jpg" fluid class="mt-2"></b-img>

                <div class="text-center">
                    <span class="font-italic small">
                        Widok menu modyfikacji - 2021
                    </span>
                </div>
            </div>

            <div class="pt-3 pb-3">
                <h5>
                    Zespół
                </h5>

                <p>
                    Na przestrzeni lat przez projekt przewinęło się dziesiątki osób, które dołożyły swoją cegiełkę - od rzetelnych testów, przez użyczenie swojego głosu do dubbingu, po gotowe projekty misji.
                    W tym miejscu chciałbym szczególnie podziękować każdemu, kto poświecił swój cenny czas na rozwój tego projektu.
                </p>

                <div>
                    Szczególne podziękowania kieruje do:

                    <div class="d-block mt-3">
                        <h5>Głównego zespółu</h5>

                        <ul class="special">
                            <li>Mikołaj "Nefarem" W.</li>
                            <li>Adam "Prażanin" O.</li>
                            <li>Rafał "Ralf32" A.</li>
                            <li>Jakub "Kao" R.</li>
                            <li>Daniel "Mietek" R.</li>
                            <li>Jan "Jakes" Cz.</li>
                            <li>Mateusz "Gelwe" F.</li>
                            <li>Jacek "Maki Roll" Ł.</li>
                            <li>Przemysław "Gogeta103" G.</li>
                            <li>Michał "Siwson" Sobol</li>
                            <li>Hubert "Thangir" Ś.</li>
                            <li>Alexander "Gwren" P. Sánchez</li>
                            <li>"Kowass"</li>
                            <li>Karol "MasterKiller" D.</li>
                        </ul>
                    </div>

                    <div class="d-block mt-3">
                        <h5>Zespółu dubbingowego</h5>

                        <ul class="special">
                            <li>Heike Steyer - Joanna "Maki" Grzegorczyk</li>
                            <li>Givi Maisuradze - Kamil "Bitewny Biurokrata" B.</li>
                            <li>Kaia Krizman - Gabriela "Gabrielle Middleton" M.</li>
                            <li>Dr. Aviradze - Kacper "PanSiwy" P.</li>
                            <li>Burłak - Bartosz "Iliach" Sadkowski</li>
                            <li>Mia Mathiassen - Agata "Kapitan" Sutkowska</li>
                            <li>Arabski żołnierz - Monika "Tosia" Witaszczyk</li>
                            <li>Kurt Schmidt - Damian Czarnocki</li>
                            <li>Johnny Harris - "PiastBoromir"</li>
                            <li>Omar Ali - Jan "Jakes" Cz.</li>
                            <li>Mike Turssen - Adam "Prażanin" O.</li>
                            <li>Amerykański żołnierz - Anna "Asirra" Pisarek</li>
                            <li>Robert Farmer, Krastyo Markov, Abdul Shariff - Michał "Siwson" Sobol</li>
                            <li>Olaf Larsson - Bartłomiej Zaryczny</li>
                            <li>Sophia Spyros - Kinga Zaryczna</li>
                            <li>Rolf Bergkampf - "MocnyBrowarek"</li>
                            <li>Gen. Morozov - "Mart"</li>
                            <li>Nia Maisuradze - "Lutra"</li>
                            <li>Muchin - "0rzeł"</li>
                            <li>Arabski żołnierz - Tryt</li>
                        </ul>
                    </div>

                    <div class="d-block mt-3">
                        <h5>Specjalne podziękowania</h5>

                        <ul class="special">
                            <li>Stuart "Stucuk" C.</li>
                            <li>Petr "Sali" S.</li>
                            <li>Paweł "Kvantovy" S.</li>
                            <li>Rafał "MarkedOne" M.</li>
                            <li>Jan "Jakes" Cz.</li>
                            <li>Konstanty "Livid" K.</li>
                            <li>"Bezimienny111"</li>
                            <li>"Lutel"</li>
                            <li>"MocnyBrowarek"</li>
                            <li>"Pecetowo"</li>
                            <li>"Seri Ray"</li>
                            <li>Artur "arturc20" C.</li>
                        </ul>
                    </div>

                    <p class="pt-2">
                        Jeszcze raz, wielkie dzięki. Bez was nie było by tego projektu :)
                    </p>

                </div>
            </div>
        </article>

        <div class="card-body">
            <comments section="about"/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                language: 'getLanguageData'
            }),
        },
    }
</script>

