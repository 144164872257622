<template>
    <div>
        <div class="d-flex justify-content-center vh-100" v-if="appQueue > 0">
            <div class="spinner-grow align-self-center" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow align-self-center" style="width: 6rem; height: 6rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow align-self-center" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <transition name="fade">
            <div class="w-100" v-show="appQueue === 0">
                <nav class="navbar navbar-expand-md sticky-top navbar-dark bg-dark-blue-2">
                    <button class="navbar-toggler navbar-toggler-left" type="button" data-toggle="collapse" data-target="#navbar-top">
                        <span class="navbar-toggler-icon"/>
                    </button>

                    <router-link :to="{ name: 'home' }" class="navbar-brand noselect">
                        Sand of Siberia
                    </router-link>

                    <div class="collapse navbar-collapse noselect" id="navbar-top">
                        <ul class="navbar-nav">
                            <div class="dropdown">
                                <a class="dropdown-toggle nav-link" href="#" role="button" id="dropdownDetailsLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ trans('layout.details') }}
                                </a>

                                <div class="dropdown-menu dropdown-menu-right bg-dark-blue-2 border-blue text-center" aria-labelledby="dropdownDetailsLink">
                                    <router-link :to="{ name: 'about' }" class="dropdown-item nav-link" :class="{ active: $route.name === 'about' }">
                                        <i class="fas fa-book-reader"></i> {{ trans('layout.about') }}
                                    </router-link>

                                    <router-link :to="{ name: 'merch' }" class="dropdown-item nav-link" :class="{ active: $route.name === 'merch' }">
                                        <i class="fas fa-compact-disc"></i> {{ trans('layout.merch') }}
                                    </router-link>

                                    <!--<router-link :to="{ name: 'content' }" class="dropdown-item nav-link" :class="{ active: $route.name === 'content' }">
                                        <i class="fas fa-database"></i> {{ trans('layout.content') }}
                                    </router-link>-->

                                    <router-link :to="{ name: 'faq' }" class="dropdown-item nav-link" :class="{ active: $route.name === 'faq' }">
                                        <i class="fas fa-info"></i> {{ trans('layout.faq') }}
                                    </router-link>

                                    <router-link :to="{ name: 'gallery' }" class="dropdown-item nav-link" :class="{ active: $route.name === 'gallery' }">
                                        <i class="fas fa-images"></i> {{ trans('layout.gallery') }}
                                    </router-link>

                                    <router-link :to="{ name: 'videos' }" class="dropdown-item nav-link" :class="{ active: $route.name === 'videos' }">
                                        <i class="fab fa-youtube"></i> {{ trans('layout.video') }}
                                    </router-link>
                                </div>
                            </div>

                            <li class="nav-item text-nowrap" :class="{ active: $route.name === 'download' }">
                                <router-link :to="{ name: 'download' }" class="nav-link">
                                    <strong><i class="fas fa-download"></i> {{ trans('layout.download') }}</strong>
                                </router-link>
                            </li>

                            <div class="d-inline justify-content-center align-self-center small">
                                ({{ trans('layout.actualVer') }}: {{ actualVer }})
                            </div>
                        </ul>

                        <ul class="navbar-nav ml-auto">
                            <!--<li class="nav-item text-nowrap"  v-if="language.id === 2" :class="{ active: $route.name === 'quiz' }">
                                <router-link :to="{ name: 'quiz' }" class="nav-link">
                                    <strong><i class="fas fa-broom"></i> 1 z 10 - Quiz</strong>
                                </router-link>
                            </li>-->

                            <li class="nav-item text-nowrap">
                                <a v-on:click="$modal.show('serversModal');" class="nav-link clickable">
                                    <strong><i class="fas fa-server"></i> {{ trans('layout.activeServers') }}: {{ servers.length }}</strong>
                                </a>
                            </li>

                            <li class="nav-item text-nowrap">
                                <a v-on:click="$modal.show('paypalModal');" class="nav-link clickable">
                                    <i class="fas fa-dollar-sign"></i> {{ trans('layout.footer.support') }}
                                </a>
                            </li>

                            <li class="nav-item text-nowrap">
                                <a class="nav-link clickable" href="https://discord.gg/APWVVWQtBW" target="_blank">
                                    <i class="fab fa-discord"/> Discord
                                </a>
                            </li>

                            <div class="dropdown mr-1">
                                <a class="dropdown-toggle nav-link clickable" role="button" id="dropdownLang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <b-img :src="language.flagUrl" width="34" height="20"/>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right bg-dark-blue-2 border-blue text-center" aria-labelledby="dropdownMenuLink">
                                    <div class="dropdown-item nav-link text-left clickable" v-for="element in locale" v-on:click="changeLanguage(element.alias)">
                                        <b-img :src="element.flagUrl" :id="element.id" width="60" height="40" />
                                        <span>{{ capitalize(element.name) }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="dropdown">
                                <a class="dropdown-toggle nav-link" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ trans('layout.menu') }}
                                </a>

                                <div class="dropdown-menu dropdown-menu-right bg-dark-blue-2 border-blue text-center" aria-labelledby="dropdownMenuLink">
                                    <a href="#" class="dropdown-item nav-link" v-on:click="setVisibilityModalContact(true)">
                                        <i class="fa fa-envelope"/> {{ trans('layout.contact') }}
                                    </a>

                                    <a class="dropdown-item nav-link" href="/Dialog/index.php" target="_blank">
                                        <i class="fas fa-tools"></i> {{ trans('layout.dialog-editor') }}
                                    </a>

                                    <div class="dropdown-divider"></div>

                                    <a href="/admin" class="dropdown-item nav-link" v-show="userData.type === 'admin'">
                                        <i class="fas fa-user-cog"/> Admin
                                    </a>

                                    <a href="#" class="dropdown-item nav-link" v-if="!isLogged" v-on:click="setVisibilityModalLogin(true)">
                                        <i class="fas fa-sign-in-alt"/> {{ trans('layout.login') }}
                                    </a>

                                    <a href="#" class="dropdown-item nav-link" v-if="!isLogged" v-on:click="setVisibilityModalRegister(true)">
                                        <i class="fa fa-id-card"/> {{ trans('layout.register') }}
                                    </a>

                                    <a href="#" class="dropdown-item nav-link" v-if="isLogged" v-on:click="onClickAccount">
                                        <i class="far fa-user-circle"/> {{ trans('layout.account') }}
                                    </a>

                                    <a href="#" class="dropdown-item nav-link" v-if="isLogged" v-on:click="logOut">
                                        <i class="fas fa-sign-out-alt"/> {{ trans('layout.logout') }}
                                    </a>
                                </div>
                            </div>
                        </ul>
                    </div>
                </nav>

                <div class="container-fluid background">
                        <div class="container">
                            <div class="w-100 main-container">
                                <div class="min-vh-100 bg-dark-blue-2">
                                    <router-view></router-view>
                                </div>
                            </div>
                        </div>

                    <notifications
                        group="message"
                        position="top right"
                    />

                    <modal name="registerModal"  adaptive height="auto" class="always-top-2">

                        <div class="card bg-dark-blue-2 border-card">
                            <div class="card-body">

                                <div class="modal-icon" v-on:click="$modal.hide('registerModal')">
                                    <i class="fa fa-close"/>
                                </div>

                                <div class="modal-icon mr-2" v-on:click="registerFormReset()">
                                    <i class="fa fa-trash"/>
                                </div>

                                <div class="modal-title">
                                    {{ trans('layout.modal.register') }}
                                </div>

                                <b-form v-on:submit="register" id="registerForm" ref="registerForm">

                                    <b-form-group
                                        id="nicknameFieldset"
                                        :label="trans('layout.modal.name')"
                                        label-for="nicknameInput"
                                        type="text"
                                        :invalid-feedback="nicknameInvalid"
                                        :state="nicknameState"
                                    >
                                        <b-form-input
                                            id="nicknameInput"
                                            type="text"
                                            v-model="registerForm.nickname"
                                            :state="nicknameState"
                                            trim
                                        />
                                    </b-form-group>


                                    <b-form-group
                                        id="emailFieldset"
                                        :label="trans('layout.modal.email')"
                                        label-for="emailInput"
                                        type="email"
                                        :invalid-feedback="registerEmailInvalid"
                                        :state="emailState"
                                    >
                                        <b-form-input
                                            id="emailInput"
                                            type="email"
                                            v-model="registerForm.email"
                                            :state="emailState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        :label="trans('layout.modal.password')"
                                        label-for="passwordRInput"
                                        type="password"
                                    >
                                        <password
                                            v-model="registerForm.password"
                                            :strength="registerForm.passwordStrength"
                                            id="passwordRInput"
                                            type="password"
                                            v-on:input="updatePasswordRegister"
                                        />
                                    </b-form-group>

                                    <div class="m-5">
                                        <div class="container">
                                            <div class="row" v-if="captcha.url === ''">
                                                <div class="col"></div>

                                                <b-spinner class="m-3" variant="secondary" label="Spinning"/>

                                                <div class="col"></div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col"></div>

                                                <div class="col-8 input-captcha">
                                                    <b-img :src="captcha.url" fluid alt="Captcha"/>
                                                    <b-input-group>
                                                        <b-form-input class="rounded-0" v-model="registerForm.captcha"/>
                                                        <b-input-group-append>
                                                            <b-button class="btn-captcha-line" squared v-on:click="getCaptcha()">
                                                                <i class="fa fa-refresh" aria-hidden="true"/>
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>

                                                <div class="col"></div>
                                            </div>

                                        </div>
                                    </div>

                                    <b-form-group>
                                        <b-form-checkbox v-model="registerForm.rules">
                                            {{ trans('layout.modal.terms') }}
                                            <small class="clickable btn-link" v-on:click="onReadTerms()">
                                                ({{ trans('layout.modal.check') }})
                                            </small>
                                        </b-form-checkbox>

                                        <b-form-checkbox v-model="registerForm.newsletter">
                                            {{ trans('layout.modal.newsletter') }}
                                        </b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group
                                        id="registerFormButtonGroup"
                                    >
                                        <b-button type="submit" size="lg" class="btn-block" v-bind:class="disableRegisterForm ? 'btn-secondary' : 'btn-success'" :disabled="disableRegisterForm">
                                            {{ trans('layout.modal.createAccount') }}
                                        </b-button>
                                    </b-form-group>

                                </b-form>

                            </div>
                        </div>
                    </modal>

                    <modal name="loginModal"  adaptive height="auto" class="always-top-2">

                        <div class="card bg-dark-blue-2 border-card">
                            <div class="card-body">

                                <div class="modal-icon" v-on:click="$modal.hide('loginModal')">
                                    <i class="fa fa-close"/>
                                </div>

                                <div class="modal-icon mr-2" v-on:click="loginFormReset()">
                                    <i class="fa fa-trash"/>
                                </div>

                                <div class="modal-title">
                                    {{ trans('layout.modal.login') }}
                                </div>

                                <b-form v-on:submit="logIn" id="loginForm" ref="loginForm">
                                    <b-form-group
                                        id="loginFieldset"
                                        :label="trans('layout.modal.email')"
                                        label-for="loginInput"
                                        type="email"
                                        :invalid-feedback="loginInvalid"
                                        :state="loginState"
                                    >
                                        <b-form-input
                                            id="loginInput"
                                            type="email"
                                            v-model="loginForm.login"
                                            :state="loginState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="passwordFieldset"
                                        :label="trans('layout.modal.password')"
                                        label-for="passwordInput"
                                        type="password"
                                        :invalid-feedback="passwordInvalid"
                                        :state="passwordState"
                                    >
                                        <b-form-input
                                            id="passwordInput"
                                            type="password"
                                            v-model="loginForm.password"
                                            :state="passwordState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="rememberMeFieldset"
                                        :label="trans('layout.modal.rememberMe')"
                                        label-for="rememberMeInput"
                                    >
                                        <toggle-button id="rememberMeInput"
                                                       v-model="loginForm.rememberMe"
                                                       :value="false"
                                                       :name="rememberMe.name"
                                                       :color="rememberMe.color"
                                                       :sync="true"
                                                       :width="rememberMe.width"
                                                       :height="rememberMe.height"
                                                       :labels="rememberMe.label"
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="loginFormButtonGroup"
                                    >
                                        <b-button type="submit" size="lg" class="btn-block" v-bind:class="disableLoginForm ? 'btn-secondary' : 'btn-success'" :disabled="disableLoginForm">
                                            {{ trans('layout.modal.login') }}
                                        </b-button>

                                        <b-button type="button" variant="dark" size="sm" class="btn-block mt-3" v-on:click="setVisibilityModalForgotPassword(true)">
                                            {{ trans('layout.modal.forgotPassword') }}
                                        </b-button>

                                        <b-button type="button" variant="dark" size="sm" class="btn-block" v-on:click="setVisibilityModalRegister(true)">
                                            {{ trans('layout.modal.noAccount') }}
                                        </b-button>
                                    </b-form-group>

                                </b-form>
                            </div>
                        </div>

                    </modal>

                    <modal name="forgotPasswordModal"  adaptive height="auto" class="always-top-2">

                        <div class="card bg-dark-blue-2 border-card">
                            <div class="card-body">

                                <div class="modal-icon" v-on:click="$modal.hide('forgotPasswordModal')">
                                    <i class="fa fa-close"/>
                                </div>

                                <div class="modal-icon mr-2" v-on:click="forgotPasswordFormReset()">
                                    <i class="fa fa-trash"/>
                                </div>

                                <div class="modal-title">
                                    {{ trans('layout.modal.changePassword') }}
                                </div>

                                <b-form v-on:submit="forgotPassword" id="forgotPasswordForm" ref="forgotPasswordForm">

                                    <b-form-group
                                        id="forgotPasswordEmailFieldset"
                                        :label="trans('layout.modal.email')"
                                        label-for="forgotPasswordEmailInput"
                                        type="email"
                                        :invalid-feedback="forgotPasswordEmailInvalid"
                                        :state="forgotPasswordEmailState"
                                    >
                                        <b-form-input
                                            id="forgotPasswordEmailInput"
                                            type="email"
                                            v-model="forgotPasswordForm.email"
                                            :state="forgotPasswordEmailState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        :label="trans('layout.modal.newPassword')"
                                        label-for="forgotPasswordPasswordInput"
                                        type="password"
                                    >
                                        <password
                                            v-model="forgotPasswordForm.password"
                                            :strength="forgotPasswordForm.passwordStrength"
                                            id="forgotPasswordPasswordInput"
                                            type="password"
                                            v-on:input="updateForgotPassword"
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="forgotPasswordFormButtonGroup"
                                    >
                                        <b-button type="submit" size="lg" class="btn-block" v-bind:class="disableForgotPasswordForm ? 'btn-secondary' : 'btn-success'" :disabled="disableForgotPasswordForm">
                                            {{ trans('layout.modal.change') }}
                                        </b-button>
                                    </b-form-group>
                                </b-form>
                            </div>
                        </div>
                    </modal>

                    <modal name="contactModal"  adaptive height="auto" class="always-top-2">

                        <div class="card bg-dark-blue-2 border-card">
                            <div class="card-body">

                                <div class="modal-icon" v-on:click="$modal.hide('contactModal')">
                                    <i class="fa fa-close"/>
                                </div>

                                <div class="modal-icon mr-2" v-on:click="contactFormReset()">
                                    <i class="fa fa-trash"/>
                                </div>

                                <div class="modal-title">
                                    {{ trans('layout.modal.contact') }}
                                </div>

                                <b-form v-on:submit="submitContactForm" id="contactForm" ref="contactForm">

                                    <b-form-group
                                        id="contactEmailFieldset"
                                        :label="trans('layout.modal.yourEmail')"
                                        label-for="contactEmailInput"
                                        type="email"
                                        :invalid-feedback="contactEmailInvalid"
                                        :state="contactEmailState"
                                    >
                                        <b-form-input
                                            id="contactEmailInput"
                                            type="email"
                                            v-model="contact.email"
                                            :state="contactEmailState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        :label="trans('layout.modal.message')"
                                        label-for="contactMessageInput"
                                    >
                                        <b-form-textarea
                                            id="contactMessageInput"
                                            v-model="contact.text"
                                            placeholder="..."
                                            rows="6"
                                            max-rows="12"
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="contactFormButtonGroup"
                                    >
                                        <b-button type="submit" size="lg" class="btn-block" v-bind:class="disableContactForm ? 'btn-secondary' : 'btn-success'" :disabled="disableContactForm">
                                            {{ trans('layout.modal.send') }}
                                        </b-button>
                                    </b-form-group>
                                </b-form>
                            </div>
                        </div>
                    </modal>

                    <modal name="serversModal" adaptive height="auto" class="always-top-2">

                        <div class="card bg-dark-blue-2 border-card">
                            <div class="card-body">

                                <div class="modal-icon" v-on:click="$modal.hide('serversModal')">
                                    <i class="fa fa-close"/>
                                </div>

                                <div class="modal-title">
                                    {{ trans('layout.modal.servers.title') }}
                                </div>

                                <div class="text-center">
                                    <p>{{ trans('layout.modal.servers.description') }}</p>

                                    <div class="w-auto m-3">

                                        <hr class="hr">

                                        <div id="servers-id" v-if="servers.length > 0">
                                            <b-table dark striped hover sort-icon-left
                                                     :items="servers"
                                                     :fields="serversFields"
                                                     id="serversTable"
                                            >
                                            </b-table>
                                        </div>

                                        <p v-else>{{ trans('layout.modal.servers.nonActiveServers') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </modal>

                    <modal name="paypalModal" adaptive height="auto" class="always-top-2">

                        <div class="card bg-dark-blue-2 border-card">
                            <div class="card-body">

                                <div class="modal-icon" v-on:click="$modal.hide('paypalModal')">
                                    <i class="fa fa-close"/>
                                </div>

                                <div class="modal-title">
                                    {{ trans('layout.modal.paypal.title') }}
                                </div>

                                <div class="text-center">
                                    <p>{{ trans('layout.modal.paypal.description') }}</p>

                                    <div class="w-auto m-3">

                                        <hr class="hr">

                                        <div id="paypal">
                                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                                <input type="hidden" name="cmd" value="_s-xclick">
                                                <input type="hidden" name="hosted_button_id" value="N745XUCL2ZQXA">
                                                <input type="image" src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/44_Yellow_CheckOut_Pill_Button.png" name="submit" alt="PayPal – Płać wygodnie i bezpiecznie">
                                                <img alt="" src="https://www.paypalobjects.com/pl_PL/i/scr/pixel.gif" width="1" height="1">
                                            </form>
                                        </div>

                                        <hr class="hr">

                                        <div id="coffee" class="mt-3">
                                            <a href="https://buycoffee.to/serpent" target="_blank">
                                                <img alt="coffee" src="/images/coffee.png" width="373" height="50">
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </modal>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        props: [
            'extra-data',
            'language-data',
        ],
        data() {
            return {
                isLogged: false,
                language: {},
                locale: {},
                actualVer: 0,
                userData: {
                    'id' : 0,
                    'name' : '',
                    'email' : '',
                    'avatar' : '',
                    'type' : '',
                },
                validEmail: '',
                registerForm: {
                    email : '',
                    password : '',
                    nickname : '',
                    captcha : '',
                    nicknameLength: {
                        min : 3,
                        max: 100
                    },
                    rules: false,
                    newsletter : true,
                    passwordLength: {
                        min: 6,
                        max: 100,
                    },
                    passwordStrength: 0,
                    disable: false,
                },
                loginForm: {
                    login: '',
                    password: '',
                    rememberMe: false,
                    loginLength: {
                        min : 3,
                        max : 100
                    },
                    passwordLength: {
                        min : 6,
                        max : 100
                    }
                },
                forgotPasswordForm: {
                    email: '',
                    password: '',
                    passwordStrength: 0,
                    disable: false,
                },
                rememberMe: {
                    name: this.trans('layout.modal.rememberMe'),
                    width: 69,
                    height: 30,
                    label: {
                        'checked' : this.trans('layout.modal.yes'),
                        'unchecked' : this.trans('layout.modal.no'),
                    },
                    color: {
                        'checked' : '#00a357',
                        'unchecked' : '#3c4349'
                    }
                },
                captcha: {
                    url: '',
                    image: '',
                    key: '',
                },
                contact: {
                    email: '',
                    text: '',
                    disable: false,
                },
                servers: [],
                serversFields: [
                    {
                        key: 'id',
                        sortable: true,
                        label: 'ID',
                    },
                    {
                        key: 'name',
                        label: this.trans('layout.modal.name'),
                    },
                    {
                        key: 'ver',
                        label: this.trans('layout.modal.version'),
                    },
                    {
                        key: 'date',
                        sortable: true,
                        label: this.trans('layout.modal.date'),
                    },
                ],
            }
        },
        computed: {
            ...mapGetters({
                appQueue: 'getAppQueue'
            }),
            nicknameState() {
                let nickname = this.registerForm.nickname;
                let nicknameLength = this.registerForm.nicknameLength;

                if (nickname.length === 0) {
                    return null;
                }

                return (nickname.length >= nicknameLength.min && nickname.length <= nicknameLength.max);
            },
            emailState() {
                let email = this.registerForm.email;

                if (email.length === 0) {
                    return null;
                }

                return this.validateEmail(email);
            },
            loginState() {
                let login = this.loginForm.login;
                let loginLength = this.loginForm.loginLength;

                if (login.length === 0) {
                    return null;
                }

                return (login.length >= loginLength.min && login.length <= loginLength.max && this.validateEmail(login));
            },
            passwordState() {
                let password = this.loginForm.password;
                let passwordLength = this.loginForm.passwordLength;

                if (password.length === 0) {
                    return null;
                }

                return (password.length >= passwordLength.min && password.length <= passwordLength.max);
            },
            forgotPasswordEmailState() {
                let email = this.forgotPasswordForm.email;

                return this.validateEmail(email);
            },
            forgotPasswordPasswordState() {
                let password = this.forgotPasswordForm.password;
                let passwordLength = this.loginForm.passwordLength;

                if (password.length === 0) {
                    return null;
                }

                return (password.length >= passwordLength.min && password.length <= passwordLength.max);
            },
            contactEmailState() {
                if (this.contact.email.length === 0)
                    return null;

                return this.validateEmail(this.contact.email);
            },
            disableRegisterForm() {
                return !(this.nicknameState &&
                         this.emailState &&
                         this.registerForm.passwordStrength >= 3 &&
                         this.registerForm.captcha === this.captcha.key &&
                         this.registerForm.rules &&
                         !this.registerForm.disable);
            },
            disableLoginForm() {
                return !(this.loginState && this.passwordState);
            },
            disableForgotPasswordForm() {
                return !(this.forgotPasswordEmailState && this.forgotPasswordPasswordState) ||
                         this.forgotPasswordForm.passwordStrength < 3 ||
                         this.forgotPasswordForm.disable;
            },
            disableContactForm() {
                return !this.contactEmailState || this.contact.text.length === 0 || this.contact.disable;
            },
            loginInvalid() {
                let login = this.loginForm.login;
                let loginLength = this.loginForm.loginLength;

                if (login.length === 0) {
                    return '';
                }

                if (login.length < loginLength.min) {
                    return this.transP('auth.loginMinLength', { 'l' : loginLength.min });
                }

                if (login.length > loginLength.max) {
                    return this.trans('auth.loginMaxLength');
                }

                if (!this.validateEmail(login)) {
                    return this.trans('auth.loginInvalid');
                }
            },
            nicknameInvalid() {
                let nickname = this.registerForm.nickname;
                let nicknameLength = this.registerForm.nicknameLength;

                if (nickname.length === 0) {
                    return '';
                }

                if (nickname.length < nicknameLength.min) {
                    return this.trans('auth.loginTooShort');
                }

                if (nickname.length > nicknameLength.max) {
                    return this.trans('auth.loginTooLong');
                }
            },
            forgotPasswordEmailInvalid() {
                return this.emailInvalid(this.forgotPasswordForm.email);
            },
            registerEmailInvalid() {
                return this.emailInvalid(this.registerForm.email);
            },
            contactEmailInvalid() {
                return this.emailInvalid(this.contact.email);
            },
            passwordInvalid() {
                let password = this.loginForm.password;
                let passwordLength = this.loginForm.passwordLength;

                if (password.length === 0) {
                    return '';
                }

                if (password.length < passwordLength.min) {
                    return this.trans('auth.passwordTooShort');
                }

                if (password.length > passwordLength.max) {
                    return this.trans('auth.passwordTooLong');
                }
            }
        },
        beforeCreate: function() {
            let self = this;
            self.$store.commit('addToAppQueue');

            axios.get('/app/lang/get/').then(function(response) {
                self.locale = response.data.locale;
            });

            axios.get('/app/check_login/').then(function(response) {
                let data = response.data;

                if (data.status === 'success') {
                    delete data['status'];

                    self.setLoginStatus(data.isLogged);
                    self.setUserData(data);
                }
            });
        },
        created: function() {
            this.setVisibilityModalLogin(false);
            this.setVisibilityModalRegister(false);
            this.setVisibilityModalForgotPassword(false);
            this.setVisibilityModalContact(false);
        },
        mounted: function() {
            this.language = this.$props['languageData'];
            this.$store.commit('setLanguageData', this.language);

            this.getActualVersion();
            this.getActiveServers();
            this.getContactStatus();
            this.getCookieTerms();
            this.displayExtraData();

            this.$store.commit('removeFromAppQueue');

            let urlParts = window.location.href.split("/")
            let setContactActive = false;
            let setPaypalActive = false;

            for (let i = 0; i < urlParts.length; i++) {
                if (urlParts[i] === "contact") {
                    setContactActive = true;
                }

                if (urlParts[i] === "support") {
                    setPaypalActive = true;
                }

                if (urlParts[i] === 'discord') {
                    window.location.href = "https://discord.gg/APWVVWQtBW";
                }
            }

            if (setContactActive) {
                this.setVisibilityModalContact(true);
            }

            if (setPaypalActive) {
                this.$modal.show('paypalModal');
            }
        },
        methods: {
            displayExtraData: function() {
                const data = this.$props['extraData'];

                if (data === null || Object.entries(data).length === 0 && data.constructor === Object) {
                    return false;
                }

                this.$notify({
                    group: 'message',
                    type: (data.status) ? 'success' : 'error',
                    duration: 5000,
                    text: data.message,
                    title: data.title,
                });
            },
            changeLanguage: function(locale) {
                if (!locale) {
                    return;
                }
                window.location.href = "/app/lang/set/" + locale;
            },
            onReadTerms: function() {
                this.setVisibilityModalRegister(false);

                this.$router.push('/terms');
            },
            updatePasswordRegister: function() {
                this.registerForm.passwordStrength = this.updatePasswordStrength(this.registerForm.password);
            },
            updateForgotPassword: function() {
                this.forgotPasswordForm.passwordStrength = this.updatePasswordStrength(this.forgotPasswordForm.password);
            },
            setLoginStatus: function(value) {
                this.isLogged = value;
            },
            setVisibilityModalRegister: function(value) {
                this.$modal.hide('loginModal');
                this.$modal.hide('forgotPasswordModal');

                if (value) {
                    this.getCaptcha();
                    this.$modal.show('registerModal');
                } else {
                    this.$modal.hide('registerModal');
                }
            },
            setVisibilityModalLogin: function(value) {
                this.$modal.hide('registerModal');
                this.$modal.hide('forgotPasswordModal');

                if (value) {
                    this.$modal.show('loginModal');
                } else {
                    this.$modal.hide('loginModal');
                }
            },
            setVisibilityModalForgotPassword: function(value) {
                this.$modal.hide('forgotPasswordModal');
                this.$modal.hide('loginModal');

                if (value) {
                    this.$modal.show('forgotPasswordModal');
                } else {
                    this.$modal.hide('forgotPasswordModal');
                }
            },
            setVisibilityModalContact: function(value) {
                if (value) {
                    this.$modal.show('contactModal');
                } else {
                    this.$modal.hide('contactModal');
                }
            },
            setUserData: function(data) {
                if (data === null) {
                    this.userData = {
                        'id' : 0,
                        'name' : '',
                        'email' : '',
                        'avatar' : '',
                        'type' : '',
                    };
                    this.contact.email = '';
                    this.$store.commit('setUserData', {});
                }
                else {
                    this.userData = data;
                    this.contact.email = data.email;
                    this.$store.commit('setUserData', data);
                }
            },
            registerFormReset: function() {
                this.$refs.registerForm.reset();
                this.registerForm.nickname = '';
                this.registerForm.email = '';
                this.registerForm.password = '';
                this.registerForm.captcha = '';
                this.registerForm.rules = false;
                this.registerForm.newsletter = false;
            },
            loginFormReset: function() {
                this.$refs.loginForm.reset();
                this.loginForm.login = '';
                this.loginForm.password = '';
            },
            forgotPasswordFormReset: function() {
                this.$refs.forgotPasswordForm.reset();
                this.forgotPasswordForm.email = '';
                this.forgotPasswordForm.password = '';
            },
            contactFormReset: function() {
                this.$refs.contactForm.reset();
                this.contact.email = '';
                this.contact.text = '';
            },
            register: function(e) {
                let self = this;
                let form = this.registerForm;

                let data = {
                    'email' : form.email,
                    'nickname' : form.nickname,
                    'password' : form.password,
                    'captcha' : form.captcha,
                    'rules' : form.rules,
                    'newsletter' : form.newsletter
                };

                self.registerForm.disable = true;

                axios.post('/app/register', data).then(function(response) {
                    let status = response.data.status;

                    self.registerForm.disable = false;
                    self.registerFormReset();
                    self.getCaptcha();

                    if (status) {
                        self.setVisibilityModalRegister(false);

                        self.$notify({
                            group: 'message',
                            type: 'success',
                            duration: 8000,
                            text: response.data.message,
                            title: response.data.title,
                        });
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 3000,
                            text: response.data.message,
                            title: response.data.title,
                        });
                    }
                });
            },
            forgotPassword: function(e) {
                e.preventDefault();

                let email = this.forgotPasswordForm.email;
                let password = this.forgotPasswordForm.password;
                let self = this;
                let data = {
                    'email' : email,
                    'password' : password,
                };

                self.forgotPasswordForm.disable = true;

                axios.post('/app/password/reset', data).then(function(response) {
                    self.$refs.forgotPasswordForm.reset();

                    self.forgotPasswordForm.disable = false;
                    self.forgotPasswordForm.email = '';
                    self.forgotPasswordForm.password = '';
                    self.forgotPasswordForm.passwordStrength = 0;

                    if (response.data.status) {
                        self.setVisibilityModalForgotPassword(false);

                        self.$notify({
                            group: 'message',
                            type: 'success',
                            duration: 5000,
                            title: response.data.title,
                            text: response.data.message,
                        });
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 5000,
                            title: response.data.title,
                            text: response.data.message,
                        });
                    }
                });
            },
            getCookieTerms: function() {
                let self = this;

                axios.get('/app/terms/cookie/get/').then(function(response) {
                    let data = response.data;

                    if (data.result) {
                        self.$notify({
                            group: 'message',
                            type: 'info',
                            duration: 15000,
                            title: response.data.title,
                            text: response.data.message,
                        });
                    }
                });
            },
            getActiveServers: function() {
                let self = this;

                axios.get('/app/servers/get/').then(function(response) {
                    self.servers = response.data.result;
                });
            },
            getActualVersion: function() {
                let self = this;

                axios.get('/app/download/get-latest-version/').then(function(response) {
                    self.actualVer = response.data.result;
                });
            },
            getContactStatus: function() {
                let self = this;

                axios.get('/app/contact/get/').then(function(response) {
                    self.contact.disable = response.data.result;
                });
            },
            submitContactForm: function(e) {
                e.preventDefault();

                let self = this;
                let data = {
                    'email' : this.contact.email,
                    'text' : this.contact.text,
                };

                self.contact.disable = true;

                axios.post('/app/contact/send/', data).then(function(response) {
                    self.$refs.contactForm.reset();

                    self.contact.text = '';

                    if (response.data.success) {
                        self.setVisibilityModalContact(false);

                        self.$notify({
                            group: 'message',
                            type: 'success',
                            duration: 5000,
                            title: response.data.title,
                            text: response.data.message,
                        });
                    } else {
                        self.contact.disable = false;

                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 5000,
                            title: response.data.title,
                            text: response.data.message,
                        });
                    }
                });
            },
            getCaptcha: function() {
                let self = this;

                axios.get('/app/register/captcha').then(function(response) {
                    let data = response.data;

                    if (Object.keys(data).length) {
                        self.registerForm.captcha = '';
                        self.captcha = data;
                    }
                });
            },
            logIn: function(e) {
                e.preventDefault();

                let login = this.loginForm.login;
                let password = this.loginForm.password;
                let rememberMe = this.loginForm.rememberMe;

                let self = this;
                let data = {
                    'login' : login,
                    'password' : password,
                    'remember' : rememberMe
                };

                axios.post('/app/login/', data).then(function(response) {
                    self.$refs.loginForm.reset();
                    self.loginForm.password = '';

                    let status = response.data.status;

                    if (status === 'need_active') {
                        self.$notify({
                            group: 'message',
                            type: 'info',
                            duration: 5000,
                            title: response.data.title,
                            text: response.data.message,
                        });
                        return;
                    }

                    if (status === 'too_many_log_attempt') {
                        self.$notify({
                           group: 'message',
                           type: 'error',
                           duration: 5000,
                           title: response.data.title,
                           text: response.data.message,
                        });
                        return;
                    }

                    if (status === 'error') {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: response.data.title,
                            text: response.data.message,
                        });
                        return;
                    }

                    self.$notify({
                        group: 'message',
                        type: 'success',
                        title: response.data.title,
                        text: response.data.message,
                    });

                    self.setLoginStatus(true);
                    self.setVisibilityModalLogin(false);
                    self.setUserData(response.data);

                }, function() {

                });
            },
            logOut: function(e) {
                let self = this;

                axios.post('/app/logout/').then(function(response) {
                    if (response.data.logout) {
                        self.$notify({
                            group: 'message',
                            type: 'success',
                            title: response.data.title,
                            text: ''
                        });

                        self.setLoginStatus(false);
                        self.setUserData(null);
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: response.data.title,
                            text: ''
                        });
                    }
                }, function() {

                });
            },
            emailInvalid: function(email) {
                if (email.length === 0) {
                    return '';
                }

                if (!this.validateEmail(email)) {
                    return this.trans('auth.emailInvalid');
                }
            },
            onClickAccount: function() {
                let id = this.userData.id;

                if (id > 0) {
                    this.$router.push('/user/' + id);
                }
            }
        }
    }
</script>
