<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body" v-if="language.id === 1"> <!-- Eng -->
            <h4 class="card-title">
                {{ trans('layout.faq') }}
            </h4>

            <p>
                Below you can find a list of answers to most asked questions related to the modification.
            </p>

            <div id="faqEN">
                <p class="font-weight-bold h4">
                    Where can I find current patches for Original War?
                </p>
                <p class="font-italic">
                    All current patches can be downloaded <a href="https://update.owsupport.com/patches/" target="_blank">here</a>.
                </p>

                <p class="font-weight-bold h4">
                    How to install the latest patch for Original War?
                </p>
                <p class="font-italic">
                    Use this <a href="https://www.youtube.com/watch?v=WBG1snJzEOE" target="_blank">guide</a>.
                </p>

                <p class="font-weight-bold h4 h4">
                    I see LUA Error window - what it's that?
                </p>
                <p class="font-italic">
                    Getting the error message "Lua Error" upon starting Sand of Siberia, means your version of Original War does not meet the minimum requirements to run the modification.
                    <span class="d-block">
                        Update Original War to the newest version - sometimes the latest OW patch is not on steam.
                    </span>
                </p>

                <p class="font-weight-bold h4">
                    After installing the patch my game won't start
                </p>
                <p class="font-italic">
                    The most common reason you can't start your game after update is your antivirus software falsely flagging the executable as a virus. To remedy that, please remove the .exe file from quarantine.
                </p>

                <p class="font-weight-bold h4">
                    I don't have full english/czech/french dubbing in the game
                </p>
                <p class="font-italic">
                    All dialogues need to be recorded by someone. In American or Russian campaign, audio files may be loaded from vanilla files. Only custom polish dubbing is available. We don't plan to record english version due to lack of volunteers.
                </p>

                <p class="font-weight-bold h4">
                    I have found a bug while playing. What should i do?
                </p>
                <p class="font-italic">
                    Restart the mission, if the problem persists, contact us via the website contact form. If there is any error log, please include it as well.
                </p>

                <p class="font-weight-bold h4">
                    I want to play on language different than English or Polish. What to do?
                </p>
                <p class="font-italic">
                    Currently, only English and Polish are supported. Playing with different language settings may cause issues. We strongly recommend to use included languages..
                </p>

                <p class="font-weight-bold h4">
                    Some of dialogues are in English, some in Czech/French. How to fix it?
                </p>
                <p class="font-italic">
                    You need to download English Language Pack from this <a href="https://original-war.net/files.php?id=144">url</a> and put downloaded file into main Original War directory.
                </p>

                <p class="font-weight-bold h4">
                    How to port Hack Mod into SoS?
                </p>
                <p class="font-italic">
                    Hack Mod is built into SoS. Check this  <a href="https://www.youtube.com/watch?v=PD04JrDkOK8">video</a> to get more details (eng. subtitles)
                </p>

                <p class="font-weight-bold h4">
                    Is there any way to change interface size?
                </p>
                <p class="font-italic">
                    It's not possible at the moment.
                </p>

                <p class="font-weight-bold h4">
                    How to launch a Hack Mod?
                </p>
                <p class="font-italic">
                    Make sure that your configuration in the SoS.ini file has the `Hack_Mode=1` parameter correctly set. Then go into the game and press `F1` key button.
                </p>

                <p class="font-weight-bold h4">
                    How to get the strimmer mode to work?
                </p>
                <p class="font-italic">
                    Go to the mod's configuration - SoS.ini file, then set the parameter `Stream_Mode=1`.
                </p>

                <p class="font-weight-bold h4">
                    I would like to play together with a friend against the computer. Does this modification offer this possibility?
                </p>
                <p class="font-italic">
                    Yes, the cooperative mode has been restored in version 1.6. It currently offers 4 different scenarios for playing together.
                </p>

                <p class="font-weight-bold h4">
                    I have a problem with completing one of the missions. Is there any solution or gameplay?
                </p>
                <p class="font-italic">
                    On the <a href="https://www.youtube.com/playlist?list=PLPQLZ0MnMGcCNSqN94xhv0Wcx-UzmB3FI" target="_blank">official channel dedicated to the modification</a>, you can find full gameplay from the American campaign.
                    A full solution for the Ares campaign will also appear in the future.
                </p>

                <p class="font-weight-bold h4">
                    Does the modification offer a multiplayer mode like the basic version of the game?
                </p>
                <p class="font-italic">
                    Yes, the mod currently has 20 playable maps in multiplayer mode.
                </p>

                <p class="font-weight-bold h4">
                    I do not have the ability to host games. What can I do about it?
                </p>
                <p class="font-italic">
                    Try unblocking 27963 UDP port on your router.
                    If that doesn't work, then you can use one of our dedicated servers.
                </p>

                <p class="mt-5 pt-3" style="border-top: 1px solid #0b76b1">
                    If you have not found the answer to your question, use our Discord or send us a direct message.
                </p>

                <div class="d-inline">
                    <b-button v-on:click="clickOnDiscord()" class="mt-1"><i class="fab fa-discord"/> Discord</b-button>
                    <b-button v-on:click="clickOnContact()" class="mt-1"><i class="fa fa-envelope"/> {{ trans('layout.contact') }}</b-button>
                </div>
            </div>
        </div>

        <article class="card-body" v-else> <!-- Polski -->
            <h4 class="card-title">
                {{ trans('layout.faq') }}
            </h4>

            <p>
                Poniżej znajduje się lista odpowiedzi na najczęściej zadawane pytania związane z modyfikacją lub jej instalacją.
            </p>

            <div id="faqPL">
                <p class="font-weight-bold h4">
                    Gdzie mogę znaleźć aktualne łatki do Original War?
                </p>
                <p class="font-italic">
                    Wszystkie aktualne łatki można pobrać <a href="https://update.owsupport.com/patches/" target="_blank">tutaj</a>.
                </p>

                <p class="font-weight-bold h4">
                    Jak zainstalować najnowszą łatkę do Original War?
                </p>
                <p class="font-italic">
                    Skorzystaj z <a href="https://www.youtube.com/watch?v=WBG1snJzEOE" target="_blank">tego</a> poradnika.
                </p>

                <p class="font-weight-bold h4">
                    Na ekranie widzę "LUA ERROR" - co to takiego?
                </p>
                <p class="font-italic">
                    Jest to błąd spowodowany prawdobodobnie przez posiadanie starszej wersji LUA. Jeżeli błąd się powtarza, prosimy o kontakt.
                    <span class="d-block">
                        Upewnij się że posiadasz najnowszą wersję Original War - nie zawsze najnowszy patch jest do pobrania na Steam.
                    </span>
                </p>

                <p class="font-weight-bold h4">
                    Po zainstalowaniu nie mogę odpalić moda.
                </p>
                <p class="font-italic">
                    Najczęstszą przyczyną tego typu błędu jest traktowanie przez oprogramowanie antywirusowe pliku wykonywalnych (.exe) jako potencjalne zagrożenie. Upewnij się żadne pliki powiązane z grą nie znajdują się na kwarantannie.
                </p>

                <p class="font-weight-bold h4">
                    Znalazłem błąd podczasy gry, co robić?
                </p>
                <p class="font-italic">
                    Zrestartuj misję, jeżeli błąd się powtarza, skontaktuj się z nami za pomocą formularza kontaktowego dostępnego w serwisie. Zalecamy dołączyć do wiadomości, log błędu jeśli takowy wystąpił.
                </p>

                <p class="font-weight-bold h4">
                    Niektóre z dialogów są po polsku, a inne po angielsku. Co robić?
                </p>
                <p class="font-italic">
                    Zainstaluj polską paczkę jezykową - <a href="https://original-war.net/files.php?id=143">link</a>.
                </p>

                <p class="font-weight-bold h4">
                    Jak przenieść Hack Moda do Sand of Siberia?
                </p>
                <p class="font-italic">
                    Hack Mod jest wbudowany w modyfikację. Sprawdź ten <a href="https://www.youtube.com/watch?v=PD04JrDkOK8">film</a> aby dowiedzieć się więcej.
                </p>

                <p class="font-weight-bold h4">
                    Jest jakiś sposób na zmianę wielkości interfejsu?
                </p>
                <p class="font-italic">
                    Ta funkcja nie jest obecnie wspierana.
                </p>

                <p class="font-weight-bold h4">
                    Jak uruchomić Hack Moda?
                </p>
                <p class="font-italic">
                    Upewnij się że twoja konfiguracja w pliku SoS.ini posiada parametr `Hack_Mode=1`.
                    Następnie, w trakcie gry, wciśnij przycisk `F1` aby wywołać konsolę.
                </p>

                <p class="font-weight-bold h4">
                    Jak uruchomić tryb dla strimerów?
                </p>
                <p class="font-italic">
                    Przejdź do konfiguracji moda - plik SoS.ini, a następnie ustaw parametr `Stream_Mode=1`.
                </p>

                <p class="font-weight-bold h4">
                    Chciałbym zagrać wspólnie ze znajomym przeciw komputerowi. Czy ta modyfikacja oferuje taką możliwość?
                </p>
                <p class="font-italic">
                    Tak, w wersji 1.6 został przywrócony tryb kooperacji. Aktualnie oferuje on 4 różne scenariusze do wspólnej zabawy.
                </p>

                <p class="font-weight-bold h4">
                    Mam problem z ukończeniem jednej z misji. Czy istnieje jakaś solucja?
                </p>
                <p class="font-italic">
                    Na <a href="https://www.youtube.com/playlist?list=PLPQLZ0MnMGcCNSqN94xhv0Wcx-UzmB3FI" target="_blank">oficjalnym kanale poświeconemu modyfikacji</a>, znaleźć można pełny gameplay z kampanii amerykańskiej.
                    W przyszłości pojawi się również pełna solucja dla kampanii Ares.
                </p>

                <p class="font-weight-bold h4">
                    Czy modyfikacja oferuje tryb wieloosobowy jak podstawowa wersja gry?
                </p>
                <p class="font-italic">
                    Tak, aktualnie mod posiada 20 grywalnych map w trybie dla wielu graczy.
                </p>

                <p class="font-weight-bold h4">
                    Nie mam możliwości hostowania gier. Co mogę z tym zrobić?
                </p>
                <p class="font-italic">
                    Spróbuj odblokować port 27963 UDP na swoim routerze.
                    Jeśli nie przyniesie to skutku, wówczas możesz skorzystać z jednego z naszych serwerów dedykowanych.
                </p>

                <p class="mt-5 pt-3" style="border-top: 1px solid #0b76b1">
                    Jeśli nie znalazłeś odpowiedzi na nurtujące Cię pytanie, skorzystaj z naszego Discord'a lub wyślij do nas wiadomość.
                </p>

                <div class="d-inline">
                    <b-button v-on:click="clickOnDiscord()" class="mt-1"><i class="fab fa-discord"/> Discord</b-button>
                    <b-button v-on:click="clickOnContact()" class="mt-1"><i class="fa fa-envelope"/> {{ trans('layout.contact') }}</b-button>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {};
    },
    methods: {
        clickOnContact: function () {
            this.$modal.show('contactModal');
        },
        clickOnDiscord: function () {
            window.location.href = "https://discord.gg/APWVVWQtBW";
        }
    },
    computed: {
        ...mapGetters({
            language: 'getLanguageData'
        }),
    },
}
</script>


