<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body">
            <h4 class="card-title">
                {{ trans('download.files') }}
            </h4>

            <div v-if="loaded && files.length">
                <b-list-group v-for="(file, k) in files" :key="k"
                              class="mb-3"
                >
                    <h5>{{ file[0].category.name }}</h5>

                    <b-list-group-item v-for="element in file" :key="element.id"
                                       v-on:click="onClickFile(element)"
                                       class="flex-column align-items-start clickable"
                                       :class="element.important ? 'important' : ''"
                    >
                        <div class="d-flex w-100 justify-content-between">
                            <h4 class="mb-1">{{ element.name }}</h4>
                            <small class="text-muted">{{ element.elapsed }}</small>
                        </div>

                        <p class="mt-2 mb-2">
                            {{ element.description }}
                        </p>

                        <div class="row">
                            <!--<div class="col">
                                <small class="text-white">
                                    <strong>{{ trans('download.category') }}</strong>: {{ element.category.name }}
                                </small>
                            </div>-->

                            <div class="col">
                                <small class="text-white">
                                    <strong>{{ trans('download.size') }}</strong>: {{ element.size }}
                                </small>
                            </div>

                            <div class="col">
                                <small v-if="element.ver_required.length" class="text-white">
                                    <strong>{{ trans('download.verReq') }}</strong>: {{ element.ver_required }}
                                </small>
                            </div>
                        </div>
                    </b-list-group-item>

                    <div v-if="file[0].category.id_download_category === 1" class="mt-2">
                        <div class="d-inline">
                            <b-button v-b-toggle.collapse-download-instruction class="mt-1">{{ trans('download.help') }}</b-button>
                            <b-button v-on:click="onClickPatches()" class="mt-1">{{ trans('download.patches') }}</b-button>
                        </div>
                        <b-collapse id="collapse-download-instruction">
                            <b-embed
                                type="iframe"
                                aspect="16by9"
                                src="https://www.youtube.com/embed/WBG1snJzEOE?rel=0"
                                allowfullscreen
                            ></b-embed>
                        </b-collapse>
                    </div>
                </b-list-group>

                <comments section="download"></comments>
            </div>

            <div class="d-flex justify-content-center mt-5" v-if="!loaded">
                <b-spinner variant="secondary" label="Loading..."/>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
            files: {},
        }
    },
    beforeCreate: function() {
        this.$store.commit('addToAppQueue');
    },
    mounted() {
        this.load();
    },
    methods: {
        load: function() {
            let self = this;

            axios.get('/app/download/get/').then(function(response) {
                let data = response.data;

                if (data.success) {
                    self.files = [];

                    for (let i = 0; i < data.files.length; i++) {
                        let category = data.files[i].category.id_download_category;

                        if (typeof self.files[category] === 'undefined') {
                            self.files[category] = [];
                        }

                        self.files[category].push(data.files[i]);
                    }

                    for (let i = self.files.length; i >= 0; i--) {
                        if (typeof self.files[i] === 'undefined') {
                            self.files.splice(i, 1);
                        }
                    }

                    self.loaded = true;
                    self.$store.commit('removeFromAppQueue');
                } else {
                    self.$notify({
                        group: 'message',
                        type: 'error',
                        duration: 3000,
                        text: data.message,
                        title: data.title,
                    });
                }
            });
        },
        onClickPatches: function () {
            window.open('/patches');
        },
        onClickFile: function(file) {
            if (!file)
                return;

            let data = {
                'id' : file.id,
            };

            axios.post('/app/download/counter/', data).then(function(response) {
                let data = response.data;

                if (data.success) {
                    window.open(file.src, '_blank');
                }
            });
        }
    }
}
</script>
